import React, { useState, useEffect } from 'react';
import { PrimaryButton } from '../../general/Buttons';
// bootstrap
import { Container, Row, Col, Form, Button, Modal, Spinner } from 'react-bootstrap';
// style
import styled from '@emotion/styled'
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import Swal from 'sweetalert2';
// navigation
import { useNavigate } from 'react-router-dom';
// redux
import { useDispatch, useSelector } from 'react-redux';
//Actions
import { NewUserAction } from '../../../redux/actions/NewUser';
import clienteAxios from '../../../config/Axios';

const FormGeneral = styled(Form)`
    label{
        font-weight:500;
    }
`

const ModalCreate = (props) => {

    let token = useSelector(state => state.auth.token);


    let loading = useSelector(state => state.newUser.loading);

    const navigate = useNavigate()
    //DISPATCH
    const dispatch = useDispatch();
    //ACTIONS
    const NewUserFn = (body, navigate, token, onHide, setFormData, update, setUpdate) => dispatch(NewUserAction(body, navigate, token, onHide, setFormData, update, setUpdate));

    const [formdata, setFormData] = useState({
        firstname: "",
        lastname: "",
        dni: "",
        creditIDId: "",
        creditID: "",
        phone: "",
        password: "",
        confirmPassword: "",
        isActive: false
    })

    const { firstname, lastname, dni, creditIDId, creditID, phone, password, confirmPassword, isActive } = formdata;

    const readFormData = (e) => {
        setFormData({
            ...formdata,
            [e.target.name]: e.target.value
        })
    }



    const handleSubmit = (e) => {
        e.preventDefault()
        console.log(formdata)
        if (firstname === "") {
            Swal.fire({ title: 'Error', text: 'Nombre es requerido', icon: 'error' })
        } else if (lastname === "") {
            Swal.fire({ title: 'Error', text: 'Apellido es requerido', icon: 'error' })
        } else if (dni === "") {
            Swal.fire({ title: 'Error', text: 'DNI es requerido', icon: 'error' })
        }
        // else if (creditIDId === "") {
        //     Swal.fire({ title: 'Error', text: 'Cédula es requerido', icon: 'error' })
        // } 
        else if (phone === "") {
            Swal.fire({ title: 'Error', text: 'Teléfono es requerido', icon: 'error' })
        } else if (password === "") {
            Swal.fire({ title: 'Error', text: 'Contraseña es requerido', icon: 'error' })
        } else if (confirmPassword === "") {
            Swal.fire({ title: 'Error', text: 'Confirmar contraseña es requerido', icon: 'error' })
        } else if (password !== confirmPassword) {
            Swal.fire({ title: 'Error', text: 'Contraseña no coincide', icon: 'error' })
        } else {
            let onHide = props.onHide
            let update = props.update
            let setUpdate = props.setUpdate

            let body = {
                firstname: firstname,
                lastname: lastname,
                dni: dni,
                phone: phone,
                password: password,
                confirmPassword: confirmPassword,
                isActive: isActive
            }

            if (creditIDId !== "") {
                body.creditIDId = creditIDId
            }

            console.log(body)
            NewUserFn(body, navigate, token, onHide, setFormData, update, setUpdate)
        }
    }


    const validateId = (e) => {
        e.preventDefault()
        console.log(e.target.value)

        if (e.target.value !== "") {
            clienteAxios.post(`/api/credit-ids/verify-credit-id`, { code: e.target.value }, { headers: { Authorization: `Bearer ${token}` } })
                .then(res => {
                    console.log(res.data)
                    setFormData({
                        ...formdata,
                        creditIDId: res.data._id,
                        creditID: res.data.code
                    })
                })
                .catch(err => {
                    Swal.fire({ title: 'Error', text: err.response.data[0].msg, icon: 'error' })
                    console.log(err.response)
                    setFormData({
                        ...formdata,
                        creditIDId: "",
                        creditID: ""
                    })
                })
        }
    }



    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <FormGeneral onSubmit={handleSubmit}>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Crear Usuario
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className='my-5'>
                        <Col xs={12}>
                            <h4>DATOS</h4>
                            <Row className='mt-4'>
                                <Form.Group as={Col} md={6} controlId="exampleForm.ControlSelect1">
                                    <Form.Label>NOMBRE</Form.Label>
                                    <Form.Control type='text' className="mb-3" name="firstname" onChange={readFormData}>
                                    </Form.Control>
                                </Form.Group>
                                <Form.Group as={Col} md={6} controlId="exampleForm.ControlSelect2">
                                    <Form.Label>APELLIDO</Form.Label>
                                    <Form.Control type='text' className="mb-3" name="lastname" onChange={readFormData}>
                                    </Form.Control>
                                </Form.Group>
                                <Form.Group as={Col} md={6} controlId="exampleForm.ControlSelect3">
                                    <Form.Label>DNI</Form.Label>
                                    <Form.Control type='text' className="mb-3" name="dni" onChange={readFormData}>
                                    </Form.Control>
                                </Form.Group>
                                <Form.Group as={Col} md={6} controlId="exampleForm.ControlSelect4">
                                    <Form.Label>ID CRÉDITO</Form.Label>
                                    <Form.Control type='text' className="mb-3" autoComplete={false} value={creditID} name="creditID" onBlur={validateId} onChange={readFormData}>
                                    </Form.Control>
                                </Form.Group>
                                <Form.Group as={Col} md={6} controlId="exampleForm.ControlSelect5">
                                    <Form.Label>NRO DE TELÉFONO</Form.Label>
                                    <Form.Control type='text' className="mb-3" name="phone" onChange={readFormData}>
                                    </Form.Control>
                                </Form.Group>
                                <Form.Group controlId="exampleForm.ControlSelect6">
                                    <Form.Label>CONTRASEÑA</Form.Label>
                                    <Form.Control type='password' className="mb-3" name="password" onChange={readFormData}>
                                    </Form.Control>
                                </Form.Group>
                                <Form.Group controlId="exampleForm.ControlSelect7">
                                    <Form.Label>CONFIRMAR CONTRASEÑA</Form.Label>
                                    <Form.Control type='text' className="mb-3" name="confirmPassword" onChange={readFormData}>
                                    </Form.Control>
                                </Form.Group>
                                <Form.Group controlId="exampleForm.ControlSelect8">
                                    <Form.Label>ESTADO</Form.Label>
                                    <Form.Check
                                        type="switch"
                                        id="custom-switch"
                                        label="Activar usuario"
                                        name="isActive" onChange={(e) => setFormData({ ...formdata, isActive: e.target.checked })}
                                    />
                                </Form.Group>
                            </Row>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <PrimaryButton variant="secondary" type='submit' disabled={loading}>
                        {loading &&
                            <>
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    className='me-2'
                                />
                                <span className="visually-hidden">Loading...</span>
                            </>
                        }
                        crear
                    </PrimaryButton>
                    <PrimaryButton type='button' onClick={props.onHide}>Cerrar</PrimaryButton>
                </Modal.Footer>
            </FormGeneral>
        </Modal>
    );
}

export default ModalCreate