import React, { useState } from 'react'
// bootstrap
import { Row, Col, Container, Form, Spinner } from 'react-bootstrap';
// style
import styled from '@emotion/styled'
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
// imahes
import fioLogo from '../../../images/fioLogo.png';
import loginBg from '../../../images/login-bg.png';
// button
import { PrimaryButton } from '../../../components/general/Buttons';
import Swal from 'sweetalert2';
// navigation
import { useNavigate } from 'react-router-dom';
// redux
import { useDispatch, useSelector } from 'react-redux';
//Actions
import { LoginAction } from '../../../redux/actions/Auth';

const ContainerForm = styled.div`
    .labelText {
		font-weight: regular;
		font-size: 1.2rem;
	}
`;

const Login = () => {

	const navigate = useNavigate()

	//DISPATCH
	const dispatch = useDispatch();
	//ACTIONS
	const LoginFn = (formdata, navigate) => dispatch(LoginAction(formdata, navigate));

	let loading = useSelector(state => state.auth.loading);

	const [formdata, setFormData] = useState({
		dni: '',
		password: '',
	})

	const { dni, password } = formdata;

	const readFormData = (e) => {
		setFormData({
			...formdata,
			[e.target.name]: e.target.value
		})
	}

	const handleSubmit = (e) => {
		e.preventDefault()
		console.log(formdata)
		if (dni === "") {
			Swal.fire({
				title: 'Error',
				text: 'DNI es requerido',
				icon: 'error',
				confirmButtonText: 'Ok'
			})
		} else if (password === "") {
			Swal.fire({
				title: 'Error',
				text: 'Password es requerido',
				icon: 'error',
				confirmButtonText: 'Ok'
			})
		} else {
			console.log(formdata)
			LoginFn(formdata, navigate)
		}
	}

	return (
		<Container fluid className="bg_white w-100">
			<Row className="d-lg-flex align-items-center justify-content-center login" css={css`border-radius:10px;`}>
				<Col xs={12} sm={8} md={7} lg={6} xl={6} className="text-center justify-content-center pt-5 pt-lg-0">
					<ContainerForm>
						<Form className="mx-auto text-left" onSubmit={handleSubmit}>
							<Row className="justify-content-center">
								<Col lg={8}>
									<img src={fioLogo} className="img-fluid" />
									<Row className="mt-5">
										<Col md={12} className="mb-4 text-start">
											<Form.Label htmlFor="user" className='labelText'>DNI</Form.Label>
											<Form.Control
												type="text"
												bgwhite="true"
												name="dni"
												onChange={readFormData}
											/>
										</Col>
									</Row>

									<Row>
										<Col md={12} className="mb-4 text-start">
											<Form.Label htmlFor="password" className='labelText'>contraseña</Form.Label>
											<Form.Control
												type="password"
												bgwhite="true"
												name="password"
												onChange={readFormData}
											/>
										</Col>
									</Row>
									<Row>
										<Col md={12} className="mb-4 text-start">
											<PrimaryButton type="submit" radius="3rem" font_size="1.6rem" className="btn-block w-100" disabled={loading}>
												{loading &&
													<>
														<Spinner
															as="span"
															animation="border"
															size="sm"
															role="status"
															aria-hidden="true"
															className='me-2'
														/>
														<span className="visually-hidden">Loading...</span>
													</>
												}
												Entrar
											</PrimaryButton>
										</Col>
									</Row>
								</Col>
							</Row>
						</Form>
					</ContainerForm>
				</Col>
				<Col xs={12} md={10} lg={6} xl={6} className="px-0 py-4 py-lg-0 text-center" css={css`
                    @media screen and (min-width: 992px) {
                        background-image: linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url(${loginBg});
                        background-position: center;
                        background-repeat: no-repeat;
                        background-size: cover;
                        height: 100vh;
                        }
                    `}>
				</Col>
			</Row>
		</Container>
	);
}

export default Login;