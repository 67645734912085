import React, { useState, useEffect } from 'react';
import { PrimaryButton } from '../../general/Buttons';
// bootstrap
import { Container, Row, Col, Form, Button, Modal, Spinner } from 'react-bootstrap';
// style
import styled from '@emotion/styled'
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'

import { Categories } from './structure/index';
import Swal from 'sweetalert2';
// navigation
import { useNavigate } from 'react-router-dom';
// redux
import { useDispatch, useSelector } from 'react-redux';
//Actions
import { NewOpportunityAction } from '../../../redux/actions/NewOportunity';


const ModalCreate = (props) => {

    let token = useSelector(state => state.auth.token);
    let loading = useSelector(state => state.newOpportunity.loading);

    const navigate = useNavigate()
    //DISPATCH
    const dispatch = useDispatch();
    //ACTIONS
    const NewOpportunityFn = (body, navigate, token, onHide, update, setUpdate) => dispatch(NewOpportunityAction(body, navigate, token, onHide, update, setUpdate));

    // ---------------------------- LEVEL ONE ----------------------------

    const [levelOne, setLevelOne] = useState({
        name: '',
        status: '',
        description: '',
        buttonText: ''
    })

    const { name, status, description } = levelOne;

    const readLevelOne = (e) => {
        setLevelOne({
            ...levelOne,
            [e.target.name]: e.target.value
        })
    }
    // ---------------------------- LEVEL TWO ----------------------------

    let defaultState = {
        title: "",
        description: "",
        link: "",
        // typeLink: "",
        // files: [
        //     {
        //         name: "",
        //         file: "",
        //         originalName: "",
        //     }
        // ]
    }


    const [categories, setCategories] = useState([defaultState]);


    const handleOnChange = (iLevel1, ilevel2, name, value, level) => {
        const copyCategory = [...categories];

        if (level === "levelOne") {
            copyCategory[iLevel1][name] = value;
            setCategories(copyCategory);
        } else {
            copyCategory[iLevel1].files[ilevel2][name] = value;
            setCategories(copyCategory);
        }
    }


    const handleOnAdd = () => {
        setCategories(categories.concat(defaultState));
    };

    const handleOnAddFile = (i) => {
        const copyCategory = [...categories];

        if (copyCategory[i].files === undefined) {
            copyCategory[i].files = [{
                name: "",
                file: "",
                originalName: "",
            }]
        } else {
            copyCategory[i].files.push({
                name: "",
                file: "",
                originalName: "",
            })
        }

        setCategories(copyCategory);
    };

    const handleOnRemove = index => {
        const copyCategory = [...categories];
        copyCategory.splice(index, 1);
        setCategories(copyCategory);
    };

    const handleOnRemoveFile = (ilevel1, ilevel2) => {
        console.log(ilevel1, ilevel2);
        const copyCategory = [...categories];
        copyCategory[ilevel1].files.splice(ilevel2, 1);
        setCategories(copyCategory);
    };



    // ---------------------------- END ----------------------------


    const handleCreate = () => {

        if (name === "") {
            Swal.fire({ title: 'Error', text: 'El nombre de la oportunidad es requerido', icon: 'error' })
        } else if (description === "") {
            Swal.fire({ title: 'Error', text: 'La descripción de la oportunidad es requedida', icon: 'error' })
        } else if (status === "") {
            Swal.fire({ title: 'Error', text: 'El estado de la oportunidad es requerido', icon: 'error' })
        }

        else {

            let error = false;

            categories.map(category => {
                if (category.title === "") {
                    Swal.fire({ title: 'Error', text: 'El titulo de la categoría es requerido', icon: 'error' })
                    error = true
                } else if (category.description === "") {
                    Swal.fire({ title: 'Error', text: 'La descripción de la categoría es requerida', icon: 'error' })
                    error = true
                } else if (category.link === "") {
                    Swal.fire({ title: 'Error', text: 'El link de la categoría es requerido', icon: 'error' })
                    error = true
                }
            })

            if (!error) {
                // name
                // status
                // description
                // buttonText
             
                let body = {
                    categories
                }

                if (levelOne.name !== "") {
                    body.name = levelOne.name
                }
                if (levelOne.status !== "") {
                    body.status = levelOne.status
                }
                if (levelOne.description !== "") {
                    body.description = levelOne.description
                }
                if (levelOne.buttonText !== "") {
                    body.buttonText = levelOne.buttonText
                }


                console.log(body)

                let update = props.update;
                let setUpdate = props.setUpdate;
                NewOpportunityFn(body, navigate, token, props.onHide, update, setUpdate)
            }
        }
    }



    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Crear Oportunidad
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row css={css`.form-label{font-weight:500 !important;}`}>
                    <Col xs={12}>
                        <Form>
                            <Row className=''>
                                <Col xs={12}>
                                    <h4>DATOS</h4>
                                    <div className=''>
                                        <Form.Group controlId="exampleForm.ControlSelect1">
                                            <Form.Label>NOMBRE DE LA OPORTUNIDAD</Form.Label>
                                            <Form.Control
                                                type='text'
                                                className="mb-3"
                                                name="name"
                                                onChange={readLevelOne}
                                            />
                                        </Form.Group>
                                        <Form.Group controlId="exampleForm.ControlSelect2">
                                            <Form.Label>ESTADO DE LA OPORTUNIDAD</Form.Label>
                                            <Form.Control
                                                as='select'
                                                className="mb-3"
                                                name="status"
                                                onChange={readLevelOne}
                                            >
                                                <option>Seleccione un Status</option>
                                                <option value="nuevo">Nuevo</option>
                                                <option value="activo">Activo</option>
                                                <option value="inactivo">Inactivo</option>
                                                <option value="proximamente">Próximamente</option>
                                            </Form.Control>
                                        </Form.Group>
                                        <Form.Group controlId="exampleForm.ControlSelect3">
                                            <Form.Label>DESCRIPCIÓN CORTA</Form.Label>
                                            <Form.Control
                                                as='textarea'
                                                rows={4}
                                                name="description"
                                                className="mb-3"
                                                onChange={readLevelOne}
                                            />
                                        </Form.Group>
                                        <Form.Group controlId="exampleForm.ControlSelect4">
                                            <Form.Label>TEXTO DEL BOTÓN</Form.Label>
                                            <Form.Control
                                                type='text'
                                                className="mb-3"
                                                name="buttonText"
                                                onChange={readLevelOne}
                                            />
                                        </Form.Group>
                                    </div>
                                </Col>
                                <Col xs={12}>
                                    <div className="App mt-3">
                                        <h4>CATEGORIAS</h4>
                                        {categories.map((category, index) => (
                                            <Categories
                                                {...category}
                                                onChange={(iLevel1, iLevel2, name, value, level) => handleOnChange(iLevel1, iLevel2, name, value, level)}
                                                onRemove={() => handleOnRemove(index)}
                                                key={index}
                                                i={index}
                                                category={category}
                                                handleOnAddFile={handleOnAddFile}
                                                handleOnRemoveFile={handleOnRemoveFile}
                                            />
                                        ))}
                                        <PrimaryButton className="mt-4" type='button' variant="secondary" onClick={handleOnAdd}>Agregar</PrimaryButton>
                                    </div>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <PrimaryButton onClick={(e) => handleCreate(e)} variant="secondary" disabled={loading}>
                    {loading &&
                        <>
                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                className='me-2'
                            />
                            <span className="visually-hidden">Loading...</span>
                        </>
                    }
                    crear
                </PrimaryButton>
                <PrimaryButton type='button' onClick={props.onHide}>Cerrar</PrimaryButton>
            </Modal.Footer>
        </Modal >
    );
}

export default ModalCreate