import {
    NEW_CREDIT_ID_START,
    NEW_CREDIT_ID_SUCCESS,
    NEW_CREDIT_ID_FAIL,
} from './ActionsTypes';
// alerts
import Swal from 'sweetalert2';
// axios
import clienteAxios from '../../config/Axios';

export function NewCreditIdAction(body, navigate, token, onHide, setFormData, update, setUpdate) {
    return (dispatch) => {
        dispatch(NewCreditIdStart())

        const config = { headers: { 'Authorization': "Bearer " + token } };//confi axios

        clienteAxios.post('/api/credit-ids/new-credit-id', body, config)
            .then((res) => {
                Swal.fire({ icon: 'success', title: 'Exito', text: 'Id de crédito creado' })
                dispatch(NewCreditIdSuccess())
                setFormData({
                    code: "",
                    isActive: ""
                })
                setUpdate(update + 1)
                onHide()
            })
            .catch(error => {
                dispatch(NewCreditIdFail())
                console.log(error.response)
                if (!error.response) {
                    Swal.fire({ icon: 'error', title: 'Lo siento', text: 'Error de conexión.' })
                } else if (error.response.status === 400) {
                    Swal.fire({ icon: 'error', title: 'Lo siento', text: 'Datos invalidos' })
                } else {
                    Swal.fire({ icon: 'error', title: 'Lo siento', text: 'Error, intente de nuevo' })
                }
            })
    }
}



export const NewCreditIdStart = () => {
    return {
        type: NEW_CREDIT_ID_START
    };
};

export const NewCreditIdSuccess = () => ({
    type: NEW_CREDIT_ID_SUCCESS
})

export const NewCreditIdFail = () => ({
    type: NEW_CREDIT_ID_FAIL,
})
