// Authentication
export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
// new user
export const NEW_USER_START = 'NEW_USER_START';
export const NEW_USER_SUCCESS = 'NEW_USER_SUCCESS';
export const NEW_USER_FAIL = 'NEW_USER_FAIL';
// update user
export const UPDATE_USER_START = 'UPDATE_USER_START';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAIL = 'UPDATE_USER_FAIL';
// new opportunity
export const NEW_OPPORTUNITIY_START = 'NEW_OPPORTUNITIY_START';
export const NEW_OPPORTUNITIY_SUCCESS = 'NEW_OPPORTUNITIY_SUCCESS';
export const NEW_OPPORTUNITIY_FAIL = 'NEW_OPPORTUNITIY_FAIL';
// update opportunity
export const UPDATE_OPPORTUNITIY_START = 'UPDATE_OPPORTUNITIY_START';
export const UPDATE_OPPORTUNITIY_SUCCESS = 'UPDATE_OPPORTUNITIY_SUCCESS';
export const UPDATE_OPPORTUNITIY_FAIL = 'UPDATE_OPPORTUNITIY_FAIL';
// new slider
export const NEW_SLIDER_START = 'NEW_SLIDER_START';
export const NEW_SLIDER_SUCCESS = 'NEW_SLIDER_SUCCESS';
export const NEW_SLIDER_FAIL = 'NEW_SLIDER_FAIL';
// update slider
export const UPDATE_SLIDER_START = 'UPDATE_SLIDER_START';
export const UPDATE_SLIDER_SUCCESS = 'UPDATE_SLIDER_SUCCESS';
export const UPDATE_SLIDER_FAIL = 'UPDATE_SLIDER_FAIL';
// New Credit ID
export const NEW_CREDIT_ID_START = 'NEW_CREDIT_ID_START';
export const NEW_CREDIT_ID_SUCCESS = 'NEW_CREDIT_ID_SUCCESS';
export const NEW_CREDIT_ID_FAIL = 'NEW_CREDIT_ID_FAIL';
// update Credit ID
export const UPDATE_CREDIT_ID_START = 'UPDATE_CREDIT_ID_START';
export const UPDATE_CREDIT_ID_SUCCESS = 'UPDATE_CREDIT_ID_SUCCESS';
export const UPDATE_CREDIT_ID_FAIL = 'UPDATE_CREDIT_ID_FAIL';
// SignUp
export const LOGOUT_START = 'LOGOUT_START';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAIL = 'LOGOUT_FAIL';