import React, { useEffect, useState } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
// style
import styled from '@emotion/styled'
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { useSelector } from 'react-redux';
import clienteAxios from '../../../config/Axios';
const data = [
    {
        name: 'Ene',
        usuarios: 10,
        pv: 2400,
        amt: 2400,
    },
    {
        name: 'Feb',
        usuarios: 5,
        pv: 1398,
        amt: 2210,
    },
    {
        name: 'Mar',
        usuarios: 40,
        pv: 9800,
        amt: 2290,
    },
    {
        name: 'Abr',
        usuarios: 20,
        pv: 3908,
        amt: 2000,
    },
    {
        name: 'May',
        usuarios: 34,
        pv: 4800,
        amt: 2181,
    },
    {
        name: 'Jun',
        usuarios: 50,
        pv: 3800,
        amt: 2500,
    },
    {
        name: 'Jul',
        usuarios: 1,
        pv: 4300,
        amt: 2100,
    },
    {
        name: 'Ago',
        usuarios: 10,
        pv: 4300,
        amt: 2100,
    },
    {
        name: 'Sep',
        usuarios: 5,
        pv: 4300,
        amt: 2100,
    },
    {
        name: 'Oct',
        usuarios: 15,
        pv: 4300,
        amt: 2100,
    },
    {
        name: 'Nov',
        usuarios: 12,
        pv: 4300,
        amt: 2100,
    },
    {
        name: 'Dic',
        usuarios: 11,
        pv: 4300,
        amt: 2100,
    },
];

const MonthUser = () => {

    let token = useSelector(state => state.auth.token);
    const [data, setData] = useState([])

    useEffect(() => {
        clienteAxios.get('/api/users/user-registered-per-month', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then(res => {
            console.log(res.data)

            let dataMont = []

            for (let i = 0; i < res.data.length; i++) {
                let month = res.data[i];
                dataMont.push({
                    name: month._id === 1 ? "Ene" : month._id === 2 ? "Feb" : month._id === 3 ? "Mar" : month._id === 4 ? "Abr" : month._id === 5 ? "May" : month._id === 6 ? "Jun" : month._id === 7 ? "Jul" : month._id === 8 ? "Ago" : month._id === 9 ? "Sep" : month._id === 10 ? "Oct" : month._id === 11 ? "Nov" : "Dic",
                    usuarios: month.count,
                })
            }

            setData(dataMont)
        }).catch(err => {
            console.log(err)
        })
    }, [])

    return (
        <div css={css`height:220px; width:100%;`}>
            <ResponsiveContainer>
                <LineChart
                    data={data}
                    margin={{
                        top: 5,
                        right: 0,
                        left: -20,
                        bottom: 5,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    {/* <Line type="monotone" dataKey="pv" stroke="#8884d8" activeDot={{ r: 8 }} /> */}
                    <Line type="monotone" dataKey="usuarios" stroke="#82ca9d" />
                </LineChart>
            </ResponsiveContainer>
        </div>
    );
}

export default MonthUser;