import React, { useState, useEffect } from 'react';
// bootstrap
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
// style
import styled from '@emotion/styled'
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
// dashboardstrcture
import DashboardStructure from '../../../general/DashboardStructure';
import { PrimaryButton } from '../../../general/Buttons';
import { Categories } from '../structure/update';
import clienteAxios from '../../../../config/Axios';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
//Actions
import { UpdateOpportunityAction } from '../../../../redux/actions/UpdateOpportunity';


const Delete = styled.div`
    top:10px;
    right:10px;
`

const Single = () => {

    let { id } = useParams();
    let token = useSelector(state => state.auth.token)
    const navigate = useNavigate()
    //DISPATCH
    const dispatch = useDispatch();
    //ACTIONS
    const UpdateOpportunityFn = (body, navigate, token, id) => dispatch(UpdateOpportunityAction(body, navigate, token, id));

    const [currentOpportunityName, setCurrentOpportunityName] = useState("");
    const [currentOpportunityDescription, setCurrentOpportunityDescription] = useState("");
    const [currentOpportunityStatus, setCurrentOpportunityStatus] = useState("");
    const [savePreview, setCurrentCategories] = useState([]);
    const [filesRemovedByCategory, setFilesRemovedByCategory] = useState([]);

    // const [titlesCategory, setTitlesCategory] = useState("");

    useEffect(() => {

        clienteAxios.get(`/api/oportunities/obtain-oportunity/${id}`, { headers: { 'Authorization': "Bearer " + token } })
            .then(res => {
                console.log(res.data.oportunity[0])

                setLevelOne({
                    name: res.data.oportunity[0].name,
                    status: res.data.oportunity[0].status,
                    description: res.data.oportunity[0].description,
                    buttonText: res.data.oportunity[0].buttonText,
                })
                setCategories(res.data.oportunity[0].categories)

                // -----------------------------------------
                setCurrentOpportunityName(res.data.oportunity[0].name)
                setCurrentOpportunityDescription(res.data.oportunity[0].description)
                setCurrentOpportunityStatus(res.data.oportunity[0].status)

                let data = []
                for (let i = 0; i < res.data.oportunity[0].categories.length; i++) {
                    const element = res.data.oportunity[0].categories[i];
                    data.push({
                        _id: element._id,
                        title: element.title,
                        description: element.description,
                        link: element.link,
                        files: element.files
                    })
                }
                setCurrentCategories(data)
                // -----------------------------------------

                // let titlesCategory = []
                // for (let i = 0; i < res.data.oportunity[0].categories.length; i++) {
                //     const element = res.data.oportunity[0].categories[i];

                //     titlesCategory.push(element.title)
                // }
                // console.log(titlesCategory)
                // setTitlesCategory(titlesCategory)
            })
            .catch(err => {
                console.log(err)
            })
    }, [])



    // ---------------------------- LEVEL ONE ----------------------------

    const [levelOne, setLevelOne] = useState({
        name: '',
        status: '',
        description: '',
        buttonText: '',
    })

    const { name, status, description, buttonText } = levelOne;

    const readLevelOne = (e) => {
        setLevelOne({
            ...levelOne,
            [e.target.name]: e.target.value
        })
    }
    // ---------------------------- LEVEL TWO ----------------------------

    let defaultState = {
        title: "",
        description: "",
        link: "",
        filesDeleted: [],
    }

    const [categories, setCategories] = useState([defaultState]);
    const [categoriesDeleted, setCategoriesDeleted] = useState([]);


    const handleOnChange = (iLevel1, ilevel2, name, value, level, _id) => {

        const copyCategory = [...categories];

        if (level === "levelOne") {
            copyCategory[iLevel1][name] = value;
            setCategories(copyCategory);
        } else {
            copyCategory[iLevel1].files[ilevel2][name] = value;
            setCategories(copyCategory);
        }
    }


    const handleOnAdd = () => {
        setCategories(categories.concat(defaultState));
    };

    const handleOnAddFile = (i) => {
        const copyCategory = [...categories];

        if (copyCategory[i].files === undefined) {
            copyCategory[i].files = [{
                name: "",
                file: "",
                originalName: "",
            }]
        } else {
            copyCategory[i].files.push({
                name: "",
                file: "",
                originalName: "",
            })
        }

        setCategories(copyCategory);
    };

    const handleOnRemove = index => {

        setCategoriesDeleted(categoriesDeleted.concat(categories[index]._id))

        const copyCategory = [...categories];
        copyCategory.splice(index, 1);
        setCategories(copyCategory);
    };

    const handleOnRemoveFile = (ilevel1, ilevel2) => {
        const copyCategory = [...categories];

        if (copyCategory[ilevel1].filesDeleted?.length > 0) {
            copyCategory[ilevel1].filesDeleted.push(copyCategory[ilevel1].files[ilevel2])
        } else {
            copyCategory[ilevel1].filesDeleted = [copyCategory[ilevel1].files[ilevel2]]
        }

        copyCategory[ilevel1].files.splice(ilevel2, 1);

        setCategories(copyCategory);
    };

    useEffect(() => {
        console.log(filesRemovedByCategory)
    }, [filesRemovedByCategory])



    // ---------------------------- END ----------------------------


    const test = (e) => {
        e.preventDefault();
        // console.log(levelOne)

        if (name === "") {
            Swal.fire({ title: 'Error', text: 'El nombre de la oportunidad es requerido', icon: 'error' })
        } else if (description === "") {
            Swal.fire({ title: 'Error', text: 'La descripción de la oportunidad es requedida', icon: 'error' })
        } else if (status === "") {
            Swal.fire({ title: 'Error', text: 'El estado de la oportunidad es requerido', icon: 'error' })
        }
        else {

            let error = false;

            categories.map(category => {
                if (category.title === "") {
                    Swal.fire({ title: 'Error', text: 'El titulo de la categoría es requerido', icon: 'error' })
                    error = true
                } else if (category.description === "") {
                    Swal.fire({ title: 'Error', text: 'La descripción de la categoría es requerida', icon: 'error' })
                    error = true
                } else if (category.link === "") {
                    Swal.fire({ title: 'Error', text: 'El link de la categoría es requerido', icon: 'error' })
                    error = true
                }
            })

            if (!error) {
                let body = {
                    ...levelOne,
                    categories
                }

                let initialState = {}

                let categoriesAdded = categories.filter(category => category._id === undefined)
                let deleted = categoriesDeleted.filter(category => category !== undefined)

                // --------------- level 1
                if (currentOpportunityName !== name) {
                    initialState.name = body.name
                }
                if (currentOpportunityDescription !== description) {
                    initialState.description = body.description
                }
                if (currentOpportunityStatus !== status) {
                    initialState.status = body.status
                }

                // --------------- level 2
                const equals = []
                body.categories.filter((current) => {
                    savePreview.filter((Preview) => {
                        if (current.title === Preview.title && current.description === Preview.description && current.link === Preview.link) {
                            equals.push(current)
                        }
                    })
                })
                const edited = body.categories.filter(d => !equals.includes(d))
                console.log("edited")
                console.log(edited)

                const dataAdjustment = []
                for (let i = 0; i < edited.length; i++) {
                    const ed = edited[i];
                    dataAdjustment.push({
                        _id: ed._id,
                        title: ed.title,
                        description: ed.description,
                        link: ed.link,
                        files: ed.files,
                        filesDeleted: ed.filesDeleted
                    })
                }

                console.log("dataAdjustment")
                console.log(dataAdjustment)
                console.log("savePreview")
                console.log(savePreview)

                let dataAdjustment2 = []

                for (let i = 0; i < savePreview.length; i++) {
                    const item1 = savePreview[i];

                    for (let j = 0; j < dataAdjustment.length; j++) {
                        const element = dataAdjustment[j];

                        if (item1._id === element._id) {
                            if (item1.title !== element.title) {
                                // CON TITLE
                                let toPush = {}

                                toPush._id = element._id
                                toPush.title = element.title
                                toPush.description = element.description
                                toPush.link = element.link
                                if (element.filesDeleted?.length > 0) { toPush.filesDeleted = element.filesDeleted }

                                let filesAdded = []
                                if (element.files.length > 0) {
                                    for (let i = 0; i < element.files.length; i++) {
                                        let file = element.files[i];
                                        if (file.file) {
                                            filesAdded.push(file)
                                        }
                                    }
                                }
                                if (filesAdded.length > 0) { toPush.filesAdded = filesAdded }

                                console.log("toPush")
                                console.log(toPush)
                                dataAdjustment2.push(toPush)

                            } else {
                                // SIN TITLE
                                let toPush = {}

                                toPush._id = element._id
                                toPush.description = element.description
                                toPush.link = element.link
                                if (element.filesDeleted?.length > 0) { toPush.filesDeleted = element.filesDeleted }

                                let filesAdded = []

                                if (element.files.length > 0) {
                                    for (let i = 0; i < element.files.length; i++) {
                                        let file = element.files[i];
                                        if (file.file) {
                                            filesAdded.push(file)
                                        }
                                    }
                                }
                                if (filesAdded.length > 0) { toPush.filesAdded = filesAdded }

                                dataAdjustment2.push(toPush)
                            }
                        }
                    }
                }

                console.log("dataAdjustment2")
                console.log(dataAdjustment2)

                if (dataAdjustment2.length > 0) {
                    initialState.categoriesUpdated = dataAdjustment2
                }

                if (categoriesAdded.length > 0) {
                    initialState.categoriesAdded = categoriesAdded
                }

                if (deleted.length > 0) {
                    initialState.categoriesDeleted = deleted
                }

                console.log(initialState)

                UpdateOpportunityFn(initialState, navigate, token, id)
            }
        }
    }


    const deleteOpportunity = () => {
        Swal.fire({
            title: '¿Estás seguro?',
            text: "¡No podrás revertir esto!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: '¡Sí, bórralo!'
        }).then((result) => {
            if (result.value) {
                clienteAxios.delete(`/api/oportunities/delete-oportunity/${id}`, { headers: { 'Authorization': "Bearer " + token } })
                    .then((res) => {
                        Swal.fire(
                            '¡Eliminado!',
                            'La oportunidad ha sido eliminado.',
                            'success'
                        )
                        navigate("/oportunidades")
                    })
                    .catch(error => {
                        console.log(error.response)
                    })
            }
        })

    }




    return (
        <DashboardStructure asideRight titlePage="Oportunidad | Salud">
            <Container fluid className="p-3">
                <Delete className='position-absolute' onClick={() => deleteOpportunity()}>
                    <Button variant="danger">ELIMINAR OPORTUNIDAD</Button>
                </Delete>
                <Form onSubmit={test}>
                    <Row className='my-5'>
                        <Col lg={5} md={12} >
                            <h4>DATOS</h4>
                            <div className='mt-4'>
                                <Form.Group controlId="exampleForm.ControlSelect1">
                                    <Form.Label>NOMBRE DE LA OPORTUNIDAD</Form.Label>
                                    <Form.Control
                                        type='text'
                                        className="mb-3"
                                        name="name"
                                        onChange={readLevelOne}
                                        value={name}
                                    />
                                </Form.Group>
                                <Form.Group controlId="exampleForm.ControlSelect2">
                                    <Form.Label>ESTADO DE LA OPORTUNIDAD</Form.Label>
                                    <Form.Control
                                        as='select'
                                        className="mb-3"
                                        name="status"
                                        onChange={readLevelOne}
                                        value={status}
                                    >
                                        <option>Seleccione un Status</option>
                                        <option value="nuevo">Nuevo</option>
                                        <option value="activo">Activo</option>
                                        <option value="inactivo">Inactivo</option>
                                        <option value="proximamente">Próximamente</option>
                                    </Form.Control>
                                </Form.Group>
                                <Form.Group controlId="exampleForm.ControlSelect3">
                                    <Form.Label>DESCRIPCIÓN CORTA</Form.Label>
                                    <Form.Control
                                        as='textarea'
                                        rows={4}
                                        name="description"
                                        onChange={readLevelOne}
                                        value={description}
                                        className="mb-3"
                                    />
                                </Form.Group>
                                <Form.Group controlId="exampleForm.ControlSelect4">
                                    <Form.Label>TEXTO DEL BOTÓN</Form.Label>
                                    <Form.Control
                                        type='text'
                                        name="buttonText"
                                        onChange={readLevelOne}
                                        value={buttonText}
                                    />
                                </Form.Group>
                                <PrimaryButton type="submit" className="mt-5" variant="secondary">Actualizar</PrimaryButton>
                            </div>
                        </Col>
                        <Col lg={5} md={12}>
                            <div className="App">
                                <h4>CATEGORIAS</h4>
                                {categories.map((category, index) => (
                                    <Categories
                                        {...category}
                                        onChange={(iLevel1, iLevel2, name, value, level, _id) => handleOnChange(iLevel1, iLevel2, name, value, level, _id)}
                                        onRemove={() => handleOnRemove(index)}
                                        key={index}
                                        i={index}
                                        url={category.link}
                                        category={category}
                                        handleOnAddFile={handleOnAddFile}
                                        handleOnRemoveFile={handleOnRemoveFile}
                                    />
                                ))}
                                <PrimaryButton className="mt-4" type='button' variant="secondary" onClick={handleOnAdd}>Agregar</PrimaryButton>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </Container>
        </DashboardStructure>
    );
}

export default Single;