import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import io from 'socket.io-client';

export const useSocket = (serverPath) => {

  const [socket, setSocket] = useState(null);
  const [online, setOnline] = useState(false);

  const connectSocket = useCallback((auth) => {

    // const token = localStorage.getItem('token');
    const token = auth.token

    const socketTemp = io.connect(serverPath, {
      transports: ['websocket'],
      autoConnect: true,
      forceNew: true,
      query: {
        token
      }
    });
    setSocket(socketTemp);
    console.log('Successfully connected')
  }, [serverPath]);

  const disconnectSocket = useCallback(() => {
    socket?.disconnect();
    console.log('successfully disconnected')
  }, [socket]);

  useEffect(() => {
    setOnline(socket?.connected);
  }, [socket])

  useEffect(() => {
    socket?.on('connect', () => setOnline(true));
  }, [socket])

  useEffect(() => {
    socket?.on('disconnect', () => setOnline(false));
  }, [socket])

  return {
    socket,
    online,
    connectSocket,
    disconnectSocket
  }
}