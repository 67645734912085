import React, { useState, useEffect, useContext } from 'react';
// bootstrap
import { Container, Row, Col, Form, Button, Spinner } from 'react-bootstrap';
// style
import styled from '@emotion/styled'
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
// dashboardstrcture
import DashboardStructure from '../../../general/DashboardStructure';
import { PrimaryButton } from '../../../general/Buttons';
import Swal from 'sweetalert2';
import clienteAxios from '../../../../config/Axios';
import { useParams } from 'react-router-dom';
// navigation
import { useNavigate } from 'react-router-dom';
// redux
import { useDispatch, useSelector } from 'react-redux';
//Actions
import { UpdateUser } from '../../../../redux/actions/UpdateUser';
import { SocketContext } from '../../../../context/SocketContext';

const Delete = styled.div`
    top:10px;
    right:10px;
`

const Title = styled.h1`
    font-size: 1.5rem;
`
const Line = styled.div`
    border: 1px solid rgba(0,0,0,0.4);
    height: 100%;
    text-align: center;
    margin-left: 20px;
    width: 1px;
    margin: auto;
`
const Single = () => {

    let { id } = useParams();
    let token = useSelector(state => state.auth.token)
    let loading = useSelector(state => state.updateUser.loading)
    const { socket } = useContext(SocketContext);

    const navigate = useNavigate()
    //DISPATCH
    const dispatch = useDispatch();
    //ACTIONS
    const UpdateUserFn = (data, navigate, token, setFormData, id) => dispatch(UpdateUser(data, navigate, token, setFormData, id));

    const [userName, setUserName] = useState("")

    const [formdata, setFormData] = useState({
        firstname: "",
        lastname: "",
        dni: "",
        creditIDId: "",
        creditID: "",
        creditIDStatus: "",
        phone: "",
        password: "",
        confirmPassword: "",
        isActive: false
    })

    const { firstname, lastname, dni, creditIDId, creditID, creditIDStatus, phone, password, confirmPassword, isActive } = formdata;

    const readFormData = (e) => {
        setFormData({
            ...formdata,
            [e.target.name]: e.target.value
        })
    }



    useEffect(() => {
        const config = { headers: { 'Authorization': "Bearer " + token } };//confi axios

        clienteAxios.get(`/api/users/obtain-user/${id}`, config)
            .then((res) => {
                console.log(res.data)
                setFormData({
                    firstname: res.data.firstname ? res.data.firstname : "",
                    lastname: res.data.lastname ? res.data.lastname : "",
                    dni: res.data.dni ? res.data.dni : "",
                    creditID: res.data.creditIDId ? res.data.creditIDId.code : "",
                    creditIDStatus: res.data.creditIDId ? res.data.creditIDId.isActive ? "Activo" : "Inactivo" : "Sin Id de Crédito",
                    phone: res.data.phone ? res.data.phone : "",
                    password: res.data.password ? res.data.password : "",
                    confirmPassword: res.data.password ? res.data.password : "",
                    isActive: res.data.isActive ? res.data.isActive : false
                })
                setUserName(res.data.firstname + " " + res.data.lastname)
            })
            .catch(error => {
                console.log(error.response)
            })
    }, [])


    const SendPush = async () => {
        const { value: formValues } = await Swal.fire({
            title: 'Enviar notificación push',
            html:
                '<input id="swal-input1" placeholder="Asunto" class="form-control">' +
                '<textarea id="swal-input2" placeholder="Mensaje" class="form-control mt-3">',
            focusConfirm: false,
            showCloseButton: true,
            cancelButtonText: "Cancelar",
            confirmButtonText: "Enviar",
            showConfirmButton: true,
            preConfirm: () => {
                return [
                    document.getElementById('swal-input1').value,
                    document.getElementById('swal-input2').value
                ]
            }
        })

        if (formValues) {
            const config = { headers: { 'Authorization': "Bearer " + token } };//confi axios
            let body = {
                "subject": formValues[0],
                "message": formValues[1],
                "receiverId": id
            }

            if (formValues[0] !== "" && formValues[1] !== "") {
                console.log(body)
                clienteAxios.post(`/api/notifications/new-push-notification`, body, config)
                    .then((res) => {
                        console.log(res)
                        Swal.fire({
                            title: 'Notificación enviada',
                            text: 'La notificación ha sido enviada correctamente',
                            icon: 'success',
                            showConfirmButton: false,
                            timer: 1500
                        })
                    })
                    .catch(error => {
                        console.log(error.response)
                        Swal.fire({
                            title: 'Error',
                            text: 'Ha ocurrido un error',
                            icon: 'error',
                            showConfirmButton: false,
                            timer: 1500
                        })
                    })

                // socket.emit('pushNotification', {
                //     subject: formValues[0],
                //     message: formValues[1],
                //     receiverId: id
                // }, (res) => {
                //     console.log(res)
                // });
                // Swal.fire({
                //     title: 'Enviado',
                //     text: 'Se ha enviado la notificación',
                //     icon: 'success',
                //     confirmButtonText: 'Ok'
                // })
            } else {
                Swal.fire({
                    title: 'Error',
                    text: 'Debe ingresar un asunto y un mensaje',
                    icon: 'error',
                    confirmButtonText: 'Ok'
                })
            }
        }
    }


    const handleSubmit = (e) => {
        e.preventDefault();

        if (firstname === "") {
            Swal.fire({ icon: 'error', title: 'Oops...', text: 'El nombre no puede estar vacío' })
        } else if (lastname === "") {
            Swal.fire({ icon: 'error', title: 'Oops...', text: 'El apellido no puede estar vacío' })
        } else if (dni === "") {
            Swal.fire({ icon: 'error', title: 'Oops...', text: 'El DNI no puede estar vacío' })
        } else if (phone === "") {
            Swal.fire({ icon: 'error', title: 'Oops...', text: 'El teléfono no puede estar vacío' })
        } else {

            let data = {
                firstname: firstname,
                lastname: lastname,
                // dni: dni,
                phone: phone,
                isActive: isActive
            }

            if (creditIDId === "") {
                data.creditIDId = null
            } else {
                data.creditIDId = creditIDId
            }
            // if (creditIDId !== "") {
            //     data.creditIDId = creditIDId
            // }

            if (password === "" && confirmPassword !== "" || password !== "" && confirmPassword === "") {
                Swal.fire({ icon: 'error', title: 'Oops...', text: 'Las contraseñas no coinciden' })
            } else {
                if (password !== "" && confirmPassword !== "") {
                    if (password === confirmPassword) {
                        data.password = password
                        data.confirmPassword = confirmPassword
                    } else {
                        Swal.fire({ icon: 'error', title: 'Oops...', text: 'Las contraseñas no coinciden' })
                        return
                    }
                }
            }

            // console.log(data)
            UpdateUserFn(data, navigate, token, setFormData, id)

        }
    }


    const deleteUser = () => {
        Swal.fire({
            title: '¿Estás seguro?',
            text: "¡No podrás revertir esto!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: '¡Sí, bórralo!'
        }).then((result) => {
            if (result.value) {
                clienteAxios.delete(`/api/users/delete-user/${id}`, { headers: { 'Authorization': "Bearer " + token } })
                    .then((res) => {
                        Swal.fire(
                            '¡Eliminado!',
                            'El usuario ha sido eliminado.',
                            'success'
                        )
                        navigate("/usuarios")
                    })
                    .catch(error => {
                        console.log(error.response)
                    })
            }
        })

    }


    const validateId = (e) => {
        e.preventDefault()
        console.log(e.target.value)

        if (e.target.value !== "") {
            clienteAxios.post(`/api/credit-ids/verify-credit-id`, { code: e.target.value }, { headers: { Authorization: `Bearer ${token}` } })
                .then(res => {
                    console.log(res.data)
                    setFormData({
                        ...formdata,
                        creditIDId: res.data._id,
                        creditID: res.data.code
                    })
                })
                .catch(err => {
                    Swal.fire({ title: 'Error', text: err.response.data[0].msg, icon: 'error' })
                    console.log(err.response)
                    setFormData({
                        ...formdata,
                        creditIDId: "",
                        creditID: ""
                    })
                })
        } else {
            setFormData({
                ...formdata,
                creditIDId: "",
                creditID: ""
            })
        }
    }


    return (
        <DashboardStructure asideRight titlePage={`Usuario | ${userName}`}>
            <Container fluid className="p-3">
                <Delete className='position-absolute' onClick={() => deleteUser()}>
                    <Button variant="danger">ELIMINAR USUARIO</Button>
                </Delete>
                <Row className='my-5'>
                    <Col lg={7} md={12}>
                        <div className='d-flex align-items-center justify-content-between'>
                            <Title>Datos del usuario</Title>
                            <div className='btn' onClick={SendPush}>
                                <svg width="30" fill='#301C7B' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                    <path d="M256 32V51.2C329 66.03 384 130.6 384 208V226.8C384 273.9 401.3 319.2 432.5 354.4L439.9 362.7C448.3 372.2 450.4 385.6 445.2 397.1C440 408.6 428.6 416 416 416H32C19.4 416 7.971 408.6 2.809 397.1C-2.353 385.6-.2883 372.2 8.084 362.7L15.5 354.4C46.74 319.2 64 273.9 64 226.8V208C64 130.6 118.1 66.03 192 51.2V32C192 14.33 206.3 0 224 0C241.7 0 256 14.33 256 32H256zM224 512C207 512 190.7 505.3 178.7 493.3C166.7 481.3 160 464.1 160 448H288C288 464.1 281.3 481.3 269.3 493.3C257.3 505.3 240.1 512 224 512z" />
                                </svg>
                            </div>
                        </div>
                        <Form className="mx-auto text-left" onSubmit={handleSubmit}>
                            <Row className="justify-content-center">
                                <Col lg={12}>
                                    <Row className="mt-5">
                                        <Col md={6} className="mb-4 text-start">
                                            <Form.Label htmlFor="name" className='labelText'>NOMBRE</Form.Label>
                                            <Form.Control
                                                type="text"
                                                bgwhite="true"
                                                value={firstname}
                                                name="firstname" onChange={readFormData}
                                            />
                                        </Col>
                                        <Col md={6} className="mb-4 text-start">
                                            <Form.Label htmlFor="lastname" className='labelText'>APELLIDO</Form.Label>
                                            <Form.Control
                                                type="text"
                                                bgwhite="true"
                                                value={lastname}
                                                name="lastname" onChange={readFormData}
                                            />
                                        </Col>
                                        <Col md={6} className="mb-4 text-start">
                                            <Form.Label htmlFor="dni" className='labelText'>DNI</Form.Label>
                                            <Form.Control
                                                type="text"
                                                bgwhite="true"
                                                value={dni}
                                                name="dni" onChange={readFormData}
                                                disabled
                                            />
                                        </Col>
                                        <Col md={6} className="mb-4 text-start">
                                            <Form.Label htmlFor="phone" className='labelText'>NRO DE TELÉFONO</Form.Label>
                                            <Form.Control
                                                type="text"
                                                bgwhite="true"
                                                value={phone}
                                                name="phone" onChange={readFormData}
                                            />
                                        </Col>
                                        <Col md={6} className="mb-4 text-start">
                                            <Form.Label htmlFor="idcredit" className='labelText'>ID CRÉDITO</Form.Label>
                                            <Form.Control
                                                type="text"
                                                bgwhite="true"
                                                value={creditID}
                                                autoComplete={false} name="creditID" onBlur={validateId} onChange={readFormData}
                                            />
                                        </Col>
                                        <Col md={6} className="mb-4 text-start">
                                            <Form.Label htmlFor="idcredit" className='labelText'>ESTADO ID CRÉDITO</Form.Label>
                                            <Form.Control
                                                type="text"
                                                bgwhite="true"
                                                disabled
                                                value={creditIDStatus}
                                            />
                                        </Col>
                                        <Col md={12} className="mb-4 text-start">
                                            <Form.Label htmlFor="password" className='labelText'>NUEVA CONTRASEÑA</Form.Label>
                                            <Form.Control
                                                type="password"
                                                bgwhite="true"
                                                value={password}
                                                autoComplete="new-password"
                                                name="password" onChange={readFormData}
                                            />
                                        </Col>
                                        <Col md={12} className="mb-4 text-start">
                                            <Form.Label htmlFor="confirmPassword" className='labelText'>CONFIRMAR CONTRASEÑA</Form.Label>
                                            <Form.Control
                                                type="password"
                                                bgwhite="true"
                                                value={confirmPassword}
                                                autoComplete="new-password"
                                                name="confirmPassword" onChange={readFormData} x
                                            />
                                        </Col>
                                        <Col md={6} className="mb-4 text-start">
                                            <Form.Group controlId="exampleForm.ControlSelect1">
                                                <Form.Label>ESTADO</Form.Label>
                                                <Form.Check
                                                    type="switch"
                                                    id="custom-switch"
                                                    label="Activar usuario"
                                                    checked={isActive}
                                                    name="isActive" onChange={(e) => setFormData({ ...formdata, isActive: e.target.checked })}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12} className="mb-4 text-start">
                                            <PrimaryButton type="submit" radius="3rem" font_size="1.6rem" className="btn-block w-100" disabled={loading}>
                                                {loading &&
                                                    <>
                                                        <Spinner
                                                            as="span"
                                                            animation="border"
                                                            size="sm"
                                                            role="status"
                                                            aria-hidden="true"
                                                            className='me-2'
                                                        />
                                                        <span className="visually-hidden">Loading...</span>
                                                    </>
                                                }
                                                Actualizar
                                            </PrimaryButton>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
            </Container>
        </DashboardStructure>
    );
}

export default Single;