import React, { useState, useEffect } from 'react';
// bootstrap
import { Container } from 'react-bootstrap';
// style
import styled from '@emotion/styled'
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
// dashboardstrcture
import DashboardStructure from '../../general/DashboardStructure';
// components
import {
    SearchState,
    IntegratedFiltering,
} from '@devexpress/dx-react-grid';
import {
    Grid,
    Table,
    Toolbar,
    SearchPanel,
    TableHeaderRow,
} from '@devexpress/dx-react-grid-bootstrap4';
// navitaion
import { useNavigate } from 'react-router-dom';
// button
import { PrimaryButton } from '../../general/Buttons';
// modal
import CreateSlider from './modal';
import { useSelector } from 'react-redux';
import clienteAxios from '../../../config/Axios';



const Create = styled.div`
    position:absolute;
    top:10px;
    right:15px;
    button{
        z-index:99;
    }
`


const TableRow = ({ row, ...restProps }) => {
    const navigate = useNavigate()
    return (
        <Table.Row
            {...restProps}
            // eslint-disable-next-line no-alert
            onClick={() => navigate(`/slider/${row.id}`)}
            style={{
                cursor: 'pointer',
            }}
        />
    )
}

const HighlightedCell = ({ value, style, ...restProps }) => (
    <Table.Cell>
        <span
            style={{
                color: value === "Activo" ? 'green' : "red",
                fontWeight: 'bolder',
            }}
        >
            {value}
        </span>
    </Table.Cell>
);

const Cell = (props) => {
    const { column } = props;
    if (column.name === 'status') {
        return <HighlightedCell {...props} />;
    }
    return <Table.Cell {...props} />;
};

const Slider = () => {
    let token = useSelector(state => state.auth.token);
    const [modalShow, setModalShow] = useState(false);
    const [rows, setRows] = useState([]);
    const [update, setUpdate] = useState(1);

    const [columns] = useState([
        { name: 'title', title: 'Titulo' },
        { name: 'subtitle', title: 'Subtitulo' },
        { name: 'text', title: 'texto' },
    ]);

    useEffect(() => {
        const config = { headers: { 'Authorization': "Bearer " + token } };//confi axios

        clienteAxios.get('/api/sliders/obtain-sliders', config)
            .then((res) => {
                console.log(res.data)

                let data = []
                for (let i = 0; i < res.data.length; i++) {
                    const element = res.data[i];

                    data.push({
                        title: element.title,
                        subtitle: element.subtitle,
                        text: element.content,
                        id: element._id,
                    })
                }

                setRows(data)
            })
            .catch(error => {
                console.log(error.response)
            })
    }, [update])



    return (
        <DashboardStructure asideRight titlePage="Gestionar - Sliders">
            <Container fluid className="p-3 h-100">
                <div className="card h-100">
                    <Grid
                        rows={rows}
                        columns={columns}
                    >
                        <SearchState />
                        <IntegratedFiltering />
                        <Table rowComponent={TableRow} cellComponent={Cell} />
                        <TableHeaderRow />
                        <Toolbar />
                        <Create>
                            <PrimaryButton onClick={() => setModalShow(true)}>Crear Slider</PrimaryButton>
                        </Create>
                        <SearchPanel />
                    </Grid>
                </div>
            </Container>
            <CreateSlider
                show={modalShow}
                onHide={() => setModalShow(false)}
                update={update}
                setUpdate={setUpdate}
            />
        </DashboardStructure>
    );
}

export default Slider;