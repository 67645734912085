import React, { useState, useEffect } from 'react';
// bootstrap
import { Container, Row, Col } from 'react-bootstrap';
// style
import styled from '@emotion/styled'
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
// dashboardstrcture
import DashboardStructure from '../../general/DashboardStructure';
// components
import {
    SearchState,
    IntegratedFiltering,
} from '@devexpress/dx-react-grid';
import {
    Grid,
    Table,
    Toolbar,
    SearchPanel,
    TableHeaderRow,
} from '@devexpress/dx-react-grid-bootstrap4';
// navitaion
import { useNavigate } from 'react-router-dom';
// button
import { PrimaryButton } from '../../general/Buttons';
// modal
import CreateSlider from './modal';
import Swal from 'sweetalert2';

import exportFromJSON from 'export-from-json'
import clienteAxios from '../../../config/Axios';
import { useSelector } from 'react-redux';



const Create = styled.div`

`


const TableRow = ({ row, ...restProps }) => {
    const navigate = useNavigate()
    return (
        <Table.Row
            {...restProps}
            // eslint-disable-next-line no-alert
            onClick={() => navigate(`/ids-creditos/${row._id}`)}
            style={{
                cursor: 'pointer',
            }}
        />
    )
}

const HighlightedCell = ({ value, style, ...restProps }) => (
    <Table.Cell>
        <span
            style={{
                color: value === true ? 'green' : "red",
                fontWeight: 'bolder',
            }}
        >
            {value === true ? 'Activo' : 'Inactivo'}
        </span>
    </Table.Cell>
);

const Cell = (props) => {
    const { column } = props;
    if (column.name === 'isActive') {
        return <HighlightedCell {...props} />;
    }
    return <Table.Cell {...props} />;
};

const CreditsIds = () => {

    let token = useSelector(state => state.auth.token)

    const [modalShow, setModalShow] = useState(false);
    const [update, setUpdate] = useState(1);

    const [data, setRows] = useState([]);


    const [columns] = useState([
        { name: 'code', title: 'ID' },
        { name: 'createdAt', title: 'Fecha de creación' },
        { name: 'isActive', title: 'Estado' },
    ]);


    useEffect(() => {

        clienteAxios.get('/api/credit-ids/obtain-credit-ids', { headers: { Authorization: `Bearer ${token}` } })
            .then(res => {
                console.log(res.data)
                let NewData = []
                for (let i = 0; i < res.data.length; i++) {
                    const element = res.data[i];
                    NewData.push({
                        code: element.code,
                        createdAt: element.createdAt.split('T')[0],
                        isActive: element.isActive,
                        _id: element._id
                    })
                }
                setRows(NewData)
            })
            .catch(error => {
                console.log(error)
            })

    }, [update])



    const UploadFile = async () => {
        const { value: file } = await Swal.fire({
            title: 'Seleccionar Archivo',
            input: 'file',
            inputAttributes: {
                'accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                'aria-label': 'Cargar tu archivo excel'
            }
        })

        if (file) {

            console.log(file)

            Swal.fire({
                title: 'Estas seguro?',
                text: "Cargar este archivo con datos erroneos afectara a los crédito",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Si, Cargar'
            }).then((result) => {
                if (result.isConfirmed) {

                    const config = { headers: { 'Authorization': "Bearer " + token, } };

                    let data = new FormData();
                    data.append('creditIds', file);

                    clienteAxios.post('/api/credit-ids/credit-ids-bulk-load', data, config)
                        .then(res => {
                            Swal.fire(
                                'Cargado!',
                                'El archivo se cargo correctamente',
                                'success'
                            )
                            setUpdate(update + 1)
                        })
                        .catch(error => {
                            console.log(error.response)
                            Swal.fire(
                                'Error!',
                                'El archivo no se cargo correctamente',
                                'error'
                            )
                        })
                }
            })
        }
    }

    const fileName = 'download'
    const exportType = exportFromJSON.types.csv

    const ExportToExcel = () => {
        exportFromJSON({ data, fileName, exportType })
    }


    const DeleteAll = () => {
        Swal.fire({
            title: 'Estas seguro?',
            text: "Esto no podra ser revertido!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, eliminar todos'
        }).then((result) => {
            if (result.isConfirmed) {

                const config = { headers: { 'Authorization': "Bearer " + token, } };

                for (let i = 0; i < data.length; i++) {
                    const element = data[i];
                    console.log(element._id)
                    clienteAxios.delete(`/api/credit-ids/delete-credit-id/${element._id}`, config)
                        .then((res) => {
                            console.log(res.data)
                        })
                        .catch(error => {
                            console.log(error.response)
                        })

                }

                Swal.fire({
                    title: 'Eliminado!',
                    text: 'Todos los creditos se eliminaron correctamente',
                    icon: 'success',
                    confirmButtonText: 'Ok'
                })
            }
        })
    }

    return (
        <DashboardStructure asideRight titlePage="Gestionar - Ids de créditos">
            <Container fluid className="p-3 h-100">
                <Row className='h-100'>
                    <Col md={10}>
                        <div className="card h-100">
                            <Grid
                                rows={data}
                                columns={columns}
                            >
                                <SearchState />
                                <IntegratedFiltering />
                                <Table rowComponent={TableRow} cellComponent={Cell} />
                                <TableHeaderRow />
                                <Toolbar />
                                <SearchPanel />
                            </Grid>
                        </div>
                    </Col>
                    <Col md={2}>
                        <Create>
                            <PrimaryButton className='w-100' onClick={() => setModalShow(true)}>Crear Id de Crédito</PrimaryButton>
                        </Create>
                        <Create>
                            <PrimaryButton className='w-100 mt-4' onClick={() => UploadFile()}>Carga masiva</PrimaryButton>
                        </Create>
                        <Create>
                            <PrimaryButton className='w-100 mt-4' onClick={() => ExportToExcel()}>Descargar data</PrimaryButton>
                        </Create>
                        <Create>
                            <PrimaryButton className='w-100 mt-4' onClick={() => DeleteAll()}>Eliminar todos los Ids</PrimaryButton>
                        </Create>
                    </Col>
                </Row>
            </Container>
            <CreateSlider
                show={modalShow}
                onHide={() => setModalShow(false)}
                update={update}
                setUpdate={setUpdate}
            />
        </DashboardStructure>
    );
}

export default CreditsIds;