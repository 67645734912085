import { NEW_SLIDER_START, NEW_SLIDER_SUCCESS, NEW_SLIDER_FAIL } from '../actions/ActionsTypes';

const initialState = {
    success: false,
    error: null,
    loading: false,
};


export default function (state = initialState, action) {
    switch (action.type) {
        case NEW_SLIDER_START:
            return {
                ...state,
                loading: true,
                error: false
            }
        case NEW_SLIDER_SUCCESS:
            return {
                ...state,
                success: true,
                error: null,
                loading: false
            }
        case NEW_SLIDER_FAIL:
            return {
                ...state,
                success: false,
                error: true,
                loading: false,
            }
        default:
            return state;
    }
}