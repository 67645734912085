import {
    UPDATE_CREDIT_ID_START,
    UPDATE_CREDIT_ID_SUCCESS,
    UPDATE_CREDIT_ID_FAIL,
} from './ActionsTypes';
// alerts
import Swal from 'sweetalert2';
// axios
import clienteAxios from '../../config/Axios';

export function UpdateCreditIdAction(data, navigate, token, id) {
    return (dispatch) => {
        dispatch(UpdateCreditIdStart())

        const config = { headers: { 'Authorization': "Bearer " + token } };//confi axios

        clienteAxios.put(`/api/credit-ids/update-credit-id/${id}`, data, config)
            .then((res) => {
                // console.log(res.data)
                Swal.fire({ icon: 'success', title: 'Exito', text: 'Id actualizado' })
                dispatch(UpdateCreditIdSuccess())
            })
            .catch(error => {
                dispatch(UpdateCreditIdFail())
                console.log(error.response)
                if (!error.response) {
                    Swal.fire({ icon: 'error', title: 'Lo siento', text: 'Error de conexión.' })
                } else if (error.response.status === 400) {
                    Swal.fire({ icon: 'error', title: 'Lo siento', text: 'Datos invalidos' })
                } else {
                    Swal.fire({ icon: 'error', title: 'Lo siento', text: 'Error, intente de nuevo' })
                }
            })
    }
}



export const UpdateCreditIdStart = () => {
    return {
        type: UPDATE_CREDIT_ID_START
    };
};

export const UpdateCreditIdSuccess = () => ({
    type: UPDATE_CREDIT_ID_SUCCESS
})

export const UpdateCreditIdFail = () => ({
    type: UPDATE_CREDIT_ID_FAIL,
})
