import React, { useState, useEffect } from 'react';
import { PrimaryButton } from '../../general/Buttons';
// bootstrap
import { Container, Row, Col, Form, Button, Modal, Spinner } from 'react-bootstrap';
// style
import styled from '@emotion/styled'
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { NewCreditIdAction } from '../../../redux/actions/NewCreditId';

const FormGeneral = styled(Form)`
    label{
        font-weight:500;
    }
`

const ModalCreate = (props) => {

    let token = useSelector(state => state.auth.token)
    let loading = useSelector(state => state.newCreditId.loading)

    const navigate = useNavigate()
    //DISPATCH
    const dispatch = useDispatch();
    //ACTIONS
    const NewCreditIdFn = (body, navigate, token, onHide, setFormData, update, setUpdate) => dispatch(NewCreditIdAction(body, navigate, token, onHide, setFormData, update, setUpdate));

    let update = props.update;
    let setUpdate = props.setUpdate;

    const [formdata, setFormData] = useState({
        code: "",
        isActive: false
    })

    const { code, isActive } = formdata;

    const readFormData = (e) => {
        setFormData({
            ...formdata,
            [e.target.name]: e.target.value
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault()

        if (code === "") {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'El campo code es obligatorio',
            })
        } else {
            console.log(formdata)
            NewCreditIdFn(formdata, navigate, token, props.onHide, setFormData, update, setUpdate)

        }
    }

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <FormGeneral onSubmit={handleSubmit}>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Crear Id de Crédito
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className='my-5'>
                        <Col xs={12}>
                            {/* <h4>DATOS</h4> */}
                            <Row className='mt-4'>
                                <Form.Group as={Col} md={12} controlId="exampleForm.ControlSelect1">
                                    <Form.Label>ID</Form.Label>
                                    <Form.Control type='text' className="mb-3" name="code" onChange={readFormData}>
                                    </Form.Control>
                                </Form.Group>
                                {/* <Form.Group as={Col} md={12} controlId="exampleForm.ControlSelect1">
                                    <Form.Label>ESTADO</Form.Label>
                                    <Form.Check
                                        type="switch"
                                        id="custom-switch"
                                        label="Activar Id"
                                        name="isActive" onChange={(e) => setFormData({ ...formdata, isActive: e.target.checked })}
                                    />
                                </Form.Group> */}
                            </Row>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <PrimaryButton variant="secondary" disabled={loading}>
                        {loading &&
                            <>
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    className='me-2'
                                />
                                <span className="visually-hidden">Loading...</span>
                            </>
                        }
                        crear
                    </PrimaryButton>
                    <PrimaryButton onClick={props.onHide}>Cerrar</PrimaryButton>
                </Modal.Footer>
            </FormGeneral>
        </Modal>
    );
}

export default ModalCreate