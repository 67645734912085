import {
    UPDATE_OPPORTUNITIY_START,
    UPDATE_OPPORTUNITIY_SUCCESS,
    UPDATE_OPPORTUNITIY_FAIL,
} from './ActionsTypes';
// alerts
import Swal from 'sweetalert2';
// axios
import clienteAxios from '../../config/Axios';

export function UpdateOpportunityAction(body, navigate, token, id) {
    return (dispatch) => {
        dispatch(UpdateOpportunityStart())

        const config = { headers: { 'Authorization': "Bearer " + token } };//confi axios

        clienteAxios.put(`/api/oportunities/update-oportunity/${id}`, body, config)
            .then((res) => {
                // console.log(res.data)
                Swal.fire({ icon: 'success', title: 'Exito', text: 'Oportunidad actualizada con éxito' })
                dispatch(UpdateOpportunitySuccess())
            })
            .catch(error => {
                dispatch(UpdateOpportunityFail())
                console.log(error.response)
                if (!error.response) {
                    Swal.fire({ icon: 'error', title: 'Lo siento', text: 'Error de conexión.' })
                } else if (error.response.status === 400) {
                    Swal.fire({ icon: 'error', title: 'Lo siento', text: 'Datos invalidos' })
                } else {
                    Swal.fire({ icon: 'error', title: 'Lo siento', text: 'Error, intente de nuevo' })
                }
            })
    }
}



export const UpdateOpportunityStart = () => {
    return {
        type: UPDATE_OPPORTUNITIY_START
    };
};

export const UpdateOpportunitySuccess = () => ({
    type: UPDATE_OPPORTUNITIY_SUCCESS
})

export const UpdateOpportunityFail = () => ({
    type: UPDATE_OPPORTUNITIY_FAIL,
})
