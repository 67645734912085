import {
    NEW_OPPORTUNITIY_START,
    NEW_OPPORTUNITIY_SUCCESS,
    NEW_OPPORTUNITIY_FAIL,
} from './ActionsTypes';
// alerts
import Swal from 'sweetalert2';
// axios
import clienteAxios from '../../config/Axios';

export function NewOpportunityAction(body, navigate, token, onHide, update, setUpdate) {
    return (dispatch) => {
        dispatch(NewOpportunityStart())

        const config = { headers: { 'Authorization': "Bearer " + token } };//confi axios

        clienteAxios.post('/api/oportunities/new-oportunity', body, config)
            .then((res) => {
                // console.log(res.data)
                Swal.fire({ icon: 'success', title: 'Exito', text: 'Usuario creado con éxito' })
                dispatch(NewOpportunitySuccess())
                setUpdate(update + 1)
                onHide()
            })
            .catch(error => {
                dispatch(NewOpportunityFail())
                console.log(error.response)
                if (!error.response) {
                    Swal.fire({ icon: 'error', title: 'Lo siento', text: 'Error de conexión.' })
                } else if (error.response.status === 400) {
                    Swal.fire({ icon: 'error', title: 'Lo siento', text: 'Datos invalidos' })
                } else {
                    Swal.fire({ icon: 'error', title: 'Lo siento', text: 'Error, intente de nuevo' })
                }
            })
    }
}



export const NewOpportunityStart = () => {
    return {
        type: NEW_OPPORTUNITIY_START
    };
};

export const NewOpportunitySuccess = () => ({
    type: NEW_OPPORTUNITIY_SUCCESS
})

export const NewOpportunityFail = () => ({
    type: NEW_OPPORTUNITIY_FAIL,
})
