import React, { useState, useEffect } from 'react';
// bootstrap
import { Container, Row, Col } from 'react-bootstrap';
// style
import styled from '@emotion/styled'
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
// // charts
import ChartMonthlyUser from './../../structure/dashboard/MonthUsers';
import Messages from './../../structure/dashboard/Messages';

// dashboardstrcture
import DashboardStructure from '../../general/DashboardStructure';
import clienteAxios from '../../../config/Axios';
import { useSelector } from 'react-redux';

const ContianerInfo = styled.div`
    p{
        margin-bottom:0;
    }
    .title{
        font-size: 1rem;
        color:#2E2961 !important;
    }
    .info{
        font-weight: bold;
        font-size: 1.5rem;
        color: #000000;
    }
`;

const CardContainer = styled.div`
    background: #FFFFFF;
    border-radius: 15px;
    padding:1rem 2em;
    margin-bottom:1rem;
    min-height:330px;
    .title{
        font-size: 1.5rem;
        color: #000000;
        font-weight:bold;
        padding-bottom:1rem;
    }
    .recharts-layer{
        .recharts-cartesian-axis-ticks{
            g text{
                font-size:12px;
            }
        }
    }
    .containerItem{
        .views{
            background: #F5F5F5;
            font-size: 1.2rem;
            align-items: center;
            color: #000000;
            border-radius:10px;
            font-weight:500;
            padding:5px 15px;
        }
        .text{
            font-size: 1rem;
            color: #000000;
            font-weight:500;
        }
        .number{
            color: #FFFFFF;
            background: #301C7B;
            border-radius:5px;
            width:20px;
            height:30px;
            text-align:center;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    .slick-dots li button:before{
       font-size:15px;
    }
`;

const Home = () => {

    let token = useSelector(state => state.auth.token);

    const [Data, setData] = useState(null)
    const [messagesCount, setMessagesCount] = useState(null)
    const [totalUsersRegister, setTotalUsersRegister] = useState(null)
    const [weeklyRegistersCount, setWeeklyRegistersCount] = useState(null)
    const [creditsUsers, setCreditsUsers] = useState(null)


    let Dat2 = [
        {
            title: 'Jhonder Suarez',
            id: "52"
        },
        {
            title: "Capacitaciones gratuitas",
            id: "41"
        },
        {
            title: "Carmen Rodriguez",
            id: "20"
        },
        {
            title: "Oriana Delgado",
            id: "10",
            badge: "Nuevo"
        },
        {
            title: "Jade Suarez",
            id: "9"
        }
    ]


    useEffect(() => {
        clienteAxios.get('/api/oportunities/most-visited-oportunities', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then(res => {
            console.log(res.data)
            setData(res.data)
        }).catch(err => {
            console.log(err)
        })
    }, [])
    useEffect(() => {
        clienteAxios.get('/api/messages/obtain-new-messages-count', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then(res => {
            console.log(res.data)
            setMessagesCount(res.data)
        }).catch(err => {
            console.log(err)
        })
    }, [])
    useEffect(() => {
        clienteAxios.get('/api/users/tolal-users-registered', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then(res => {
            console.log(res.data)
            setTotalUsersRegister(res.data)
        }).catch(err => {
            console.log(err)
        })
    }, [])

    useEffect(() => {
        clienteAxios.get('/api/users/weekly-registers-count', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then(res => {
            console.log(res.data)
            setWeeklyRegistersCount(res.data)
        }).catch(err => {
            console.log(err)
        })
    }, [])


    useEffect(() => {
        clienteAxios.get('/api/credit-visits/obtain-credit-visits', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then(res => {
            console.log(res.data)
            setCreditsUsers(res.data)
        }).catch(err => {
            console.log(err)
        })
    }, [])


    return (
        <DashboardStructure asideRight titlePage="Dashboard">
            <Container fluid className="p-3">
                <Row className="mb-4">
                    <Col lg={4} md={6} sm={6} xs={12}>
                        <ContianerInfo>
                            <p classNane="title" css={css`color:#2E2961; font-size:1.2rem;`}>Registros en la semana</p>
                            <p className="info">{weeklyRegistersCount ? weeklyRegistersCount[0].count : "0"}</p>
                        </ContianerInfo>
                    </Col>
                    <Col lg={4} md={6} sm={6} xs={12}>
                        <ContianerInfo>
                            <p classNane="title" css={css`color:#2E2961; font-size:1.2rem;`}>Total de usuarios registrados</p>
                            <p className="info">{totalUsersRegister ? totalUsersRegister.totalUsers : "0"}</p>
                        </ContianerInfo>
                    </Col>
                    <Col lg={4} md={6} sm={6} xs={12}>
                        <ContianerInfo>
                            <p classNane="title" css={css`color:#2E2961; font-size:1.2rem;`}>Nuevos mensajes</p>
                            <p className="info">{messagesCount ? messagesCount.count : "0"}</p>
                        </ContianerInfo>
                    </Col>
                </Row>
                <Row>
                    <Col xl={4} lg={6} md={6} sm={12}>
                        <CardContainer>
                            <p className="title  border-bottom">Usuarios mensuales</p>
                            <ChartMonthlyUser />
                        </CardContainer>
                    </Col>
                    <Col xl={4} lg={6} md={6} sm={12}>
                        <CardContainer>
                            <p className="title  border-bottom">Oportunidades más visitadas</p>
                            {Data && Data.slice(0, 4).map((item, index) => (
                                <Row key={index} className="containerItem d-flex align-items-center px-4">
                                    <Col xs={1} className="px-0">
                                        <p className="mr-2 number">{index + 1}</p>
                                    </Col>
                                    <Col xs={9} className="px-0">
                                        <p className="text pl-2">{item.name.toUpperCase()}</p>
                                    </Col>
                                    <Col xs={2} className="px-0">
                                        <p className="views text-center">{item.visits}</p>
                                    </Col>
                                </Row>
                            ))}

                        </CardContainer>
                    </Col>
                    <Col xl={4} lg={6} md={6} sm={12}>
                        <CardContainer>
                            <p className="title  border-bottom">Mensajes</p>
                            <Messages />
                        </CardContainer>
                    </Col>
                    <Col xl={4} lg={6} md={6} sm={12}>
                        <CardContainer>
                            <p className="title  border-bottom">Crédito visitado</p>
                            {creditsUsers && creditsUsers.slice(0, 4).map((item, index) => (
                                <Row key={index} className="containerItem d-flex align-items-center px-4">
                                    <Col xs={1} className="px-0">
                                        <p className="mr-2 number">{index + 1}</p>
                                    </Col>
                                    <Col xs={9} className="px-0">
                                        <p className="text pl-2">{item.userId?.firstname + " " + item.userId?.lastname}</p>
                                    </Col>
                                    <Col xs={2} className="px-0">
                                        <p className="views text-center">{item.visits}</p>
                                    </Col>
                                </Row>
                            ))}
                        </CardContainer>
                    </Col>
                </Row>
            </Container>
        </DashboardStructure>
    );
}

export default Home;