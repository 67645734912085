import * as Scroll from 'react-scroll';

const scroll = Scroll.animateScroll;

export const scrollToBotton = (id) => {
  scroll.scrollToBottom({
    containerId: id,
    duration: 0,
  })
}

export const scrollToBottonAnimated = (id) => {
  scroll.scrollToBottom({
    containerId: id,
    duration: 100,
  })
}