import { combineReducers } from 'redux';
import authReducer from './Auth';
import newUserReducer from './NewUser';
import updateUserReducer from './UpdateUser';
import newOpportunityReducer from './NewOpportunity';
import newSliderReducer from './NewSlider';
import updateSliderReducer from './UpdateSlider';
import newCreditIdReducer from './NewCreditId';
import updateCreditIdReducer from './UpdateCreditId';

export default combineReducers({
    auth: authReducer,
    newUser: newUserReducer,
    updateUser: updateUserReducer,
    newOpportunity: newOpportunityReducer,
    newSlider: newSliderReducer,
    updateSlider: updateSliderReducer,
    newCreditId: newCreditIdReducer,
    updateCreditId: updateCreditIdReducer,
});