import React, { useState, useEffect, useContext } from 'react';
// bootstrap
import { Container, Row, Col } from 'react-bootstrap';
// style
import styled from '@emotion/styled'
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
// dashboardstrcture
import DashboardStructure from '../../../general/DashboardStructure';
import clienteAxios from '../../../../config/Axios';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { SendMessage } from "../../../general/SendMessage";
import { ChatContext } from '../../../../context/chat/ChatContext';
import { types } from '../../../../types/types';

import { scrollToBotton } from '../../../../helpers/scrollToBotton';
import { formatedDate } from "../../../../helpers/horaMes";

const Title = () => {
    return (
        <h1>
            Messages
        </h1>
    )
}


const MessageList = styled.div`
height:calc(100vh - 60px - 62px - 70px) !important;
    overflow-y: scroll;
    overflow-x: hidden;
    ::-webkit-scrollbar {
        width: 10px;
    }
    ::-webkit-scrollbar-track {
        background: #f1f1f1;
    }
    ::-webkit-scrollbar-thumb {
        background: #888;
    }
    ::-webkit-scrollbar-thumb:hover {
        background: #555;
    }

    .message-list { 
        padding-bottom: 1rem !important;
        list-style: none;
        padding: 0;
        margin: 0;
    }

    .user {
        font-weight: bold;
        font-size: 1rem;
        margin-right: 10px;
    }

    .text {
        font-size: 1rem;
        line-height: 1;
    }

    .timestamp {
        font-size: 0.7rem;
        color: #000;
        margin-top:5px;
        font-weight: 500;
    }

    .text-time{
        background: #ebe6e6;
        margin-bottom:10px;
        max-width: 80%;
        border-radius: 10px;
        padding: 10px 10px;
        width: auto;
    }
    
`

const Message = styled.li`
    position:relative ;
    display: flex;
    flex-direction: column;
    align-items: start;
    padding: 0px 10px;
    margin: 5px 0px ;
    .text-time{
        span{
            position: absolute;
            z-index: 100;
            display: block;
            width: 8px;
            top: 0px;
            height: 13px;
        }
    }
    ${props => (props.sender === props.currentUser) ? css`
        border-radius: 5px;
        align-items: flex-end;
        text-align: right;
        .text-time{
            position: relative !important;
            border-top-right-radius: 0px !important;
                span.noAdmin{
                    right: -8px;
                    top: -8px;
                }
            }
        }
    ` : css`
        border-radius: 5px;
        align-items: flex-start;
        text-align: left;
        .text-time{
            position: relative !important;
            border-top-left-radius: 0px !important;
            span.admin{
                left: -8px;
                top: -8px;
            }
        }
    `}
`

const ContainerGeneral = styled.div`

    `

const Single = () => {

    let token = useSelector(state => state.auth.token);
    let userId = useSelector(state => state.auth.userId);
    const { id } = useParams();

    const { chatState, dispatch } = useContext(ChatContext);
    const [cliente, setClient] = useState(null);
    // const [sender, setSender] = useState(true);
    // const [messages, setMessages] = useState([]);

    useEffect(() => {

        dispatch({
            type: types.activateChat,
            payload: id,
        })

        clienteAxios.get(`/api/messages/obtain-messages-by-admin/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then(res => {
                dispatch({
                    type: types.loadMessages,
                    payload: res.data,
                })
            })
            .catch(error => {
                console.log(error)
            }).finally(() => {
                setTimeout(() => {
                    scrollToBotton('chat');
                }, 200)
            })
    }, [])


    useEffect(() => {
        clienteAxios.get(`/api/users/obtain-user/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then(res => {
                console.log(res.data)
                setClient(res.data)
            })
            .catch(error => {
                console.log(error)
            })
    }, [])



    useEffect(() => {
        console.log(chatState)

        let noReaded = chatState.messages.filter(message => message.status === "enviado");

        let dataNoRead = []
        for (let i = 0; i < noReaded.length; i++) {
            const msj = noReaded[i];

            dataNoRead.push(
                msj._id
            )
        }

        console.log(dataNoRead)

        if (dataNoRead.length > 0) {
            let body = {
                "messagesId": dataNoRead
            }
            clienteAxios.post(`/api/messages/read-message`, body, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
                .then(res => {
                    console.log(res.data)
                })
                .catch(error => {
                    console.log(error)
                })
        }

    }, [chatState])



    // 

    // const onClick = async () => {
    //     dispatch({
    //       type: types.activarChat,
    //       payload: usuario.uid,
    //     })

    //     const resp = await fetchConToken(`mensajes/${usuario.uid}`);

    //     dispatch({
    //       type: types.cargarMensajes,
    //       payload: resp.mensajes,
    //     })
    //     setTimeout(() => {
    //       scrollToBotton('mensajes');
    //     }, 200)

    //   }
    // console.log(chatState.messages)
    return (
        <DashboardStructure asideRight titlePage={`Mensajes | ${cliente ? cliente.firstname + " " + cliente.lastname : ""}`}>
            <Container fluid className="x-3">
                <Row className=''>
                    <Col lg={7} md={12}>
                        <div className="chat" >
                            <MessageList id='chat'>
                                <ul className="message-list">
                                    {chatState.messages.map((message, i) => {
                                        return (
                                            <Message key={message._id} sender={message.from} currentUser={userId}>
                                                {chatState.messages[i - 1]?.from !== message.from ?
                                                    <div className='user'>
                                                        {message.from !== userId ? cliente?.firstname : 'Administrador'}
                                                    </div>
                                                    : null
                                                }
                                                <div className='text-time mb-0'>
                                                    {message.from === userId ?
                                                        chatState.messages[i - 1]?.from !== message.from &&
                                                        <span className='noAdmin'>
                                                            <svg viewBox="0 0 8 13" width="8" height="13" className=""><path opacity=".13" d="M5.188 1H0v11.193l6.467-8.625C7.526 2.156 6.958 1 5.188 1z"></path><path fill="#ebe6e6" d="M5.188 0H0v11.193l6.467-8.625C7.526 1.156 6.958 0 5.188 0z"></path></svg>
                                                        </span>
                                                        :
                                                        chatState.messages[i - 1]?.from !== message.from &&
                                                        <span className='admin'>
                                                            <svg viewBox="0 0 8 13" width="8" height="13" className=""><path opacity=".13" fill="#0000000" d="M1.533 3.568 8 12.193V1H2.812C1.042 1 .474 2.156 1.533 3.568z" /><path fill="#ebe6e6" d="M1.533 2.568 8 11.193V0H2.812C1.042 0 .474 1.156 1.533 2.568z" /></svg>
                                                        </span>
                                                    }
                                                    <p className='text' css={css`white-space: break-spaces;`}>
                                                        {message.content}
                                                        {message.files.length > 0 && message.files.map(file => {
                                                            return (
                                                                <div key={file._id} className="my-2">
                                                                    <a href={file.url} css={css`font-size:0.9rem;`} target='_blank'>
                                                                        {file.name}
                                                                    </a>
                                                                </div>
                                                            )
                                                        })}
                                                    </p>
                                                    <div className='timestamp'>
                                                        {formatedDate(message.createdAt)}
                                                    </div>
                                                </div>
                                            </Message>
                                        )
                                    })}
                                </ul>
                            </MessageList>
                            <SendMessage from={userId} to={id} />
                        </div>
                    </Col>
                </Row>
            </Container>
        </DashboardStructure>
    );
}

export default Single;