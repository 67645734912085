import React, { useState, useEffect } from 'react';
// bootstrap
import { Container, Row, Col, Form, Button, Spinner } from 'react-bootstrap';
// style
import styled from '@emotion/styled'
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
// dashboardstrcture
import DashboardStructure from '../../../general/DashboardStructure';
import { PrimaryButton } from '../../../general/Buttons';
import Swal from 'sweetalert2';
import clienteAxios from '../../../../config/Axios';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { UpdateCreditIdAction } from '../../../../redux/actions/UpdateCreditId';


const Delete = styled.div`
    top:10px;
    right:10px;
`

const Title = styled.h1`
    font-size: 1.5rem;
`
const Line = styled.div`
    border: 1px solid rgba(0,0,0,0.4);
    height: 100%;
    text-align: center;
    margin-left: 20px;
    width: 1px;
    margin: auto;
`
const Single = () => {
    let { id } = useParams();
    let navigate = useNavigate();
    let token = useSelector(state => state.auth.token);
    let loading = useSelector(state => state.updateCreditId.loading);

    //DISPATCH
    const dispatch = useDispatch();
    //ACTIONS
    const UpdateCreditIdFn = (body, navigate, token, id) => dispatch(UpdateCreditIdAction(body, navigate, token, id));

    const [creditId, setCreditId] = useState({
        code: "",
        isActive: false
    })
    const [currentCode, setCurrentCode] = useState("")

    let { code, isActive } = creditId;

    const SendPush = async () => {
        const { value: formValues } = await Swal.fire({
            title: 'Enviar notificación push',
            html:
                '<input id="swal-input1" placeholder="Asunto" class="form-control">' +
                '<textarea id="swal-input2" placeholder="Mensaje" class="form-control mt-3">',
            focusConfirm: false,
            showCloseButton: true,
            cancelButtonText: "Cancelar",
            confirmButtonText: "Enviar",
            showConfirmButton: true,
            preConfirm: () => {
                return [
                    document.getElementById('swal-input1').value,
                    document.getElementById('swal-input2').value
                ]
            }
        })

        if (formValues) {
            Swal.fire(JSON.stringify(formValues))
        }
    }


    useEffect(() => {
        clienteAxios.get(`/api/credit-ids/obtain-credit-id/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then(res => {
                setCreditId({
                    code: res.data.code,
                    isActive: res.data.isActive
                })
                setCurrentCode(res.data.code)
            })
            .catch(error => {
                console.log(error)
            })
    }, [])


    const handleSubmit = (e) => {
        e.preventDefault();

        if (code === "") {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'El código no puede estar vacío',
            })
        } else {
            // console.log(creditId)
            let body
            if (code !== currentCode) {
                body = {
                    code: code,
                    isActive: isActive
                }
            } else {
                body = {
                    isActive: isActive
                }
            }
            UpdateCreditIdFn(body, navigate, token, id)
        }


    }


    const deleteId = () => {
        Swal.fire({
            title: '¿Estás seguro?',
            text: "¡No podrás revertir esto!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: '¡Sí, bórralo!'
        }).then((result) => {
            if (result.value) {
                clienteAxios.delete(`/api/credit-ids/delete-credit-id/${id}`, { headers: { 'Authorization': "Bearer " + token } })
                    .then((res) => {
                        Swal.fire(
                            '¡Eliminado!',
                            'El ID ha sido eliminado.',
                            'success'
                        )
                        navigate("/ids-creditos")
                    })
                    .catch(error => {
                        console.log(error.response)
                    })
            }
        })

    }


    return (
        <DashboardStructure asideRight titlePage="Id de Crédito">
            <Container fluid className="p-3">
                <Delete className='position-absolute' onClick={() => deleteId()}>
                    <Button variant="danger">ELIMINAR ID</Button>
                </Delete>
                <Row className='my-5'>
                    <Col lg={7} md={12}>
                        <Title>Datos del id de crédito</Title>
                        <Form className="mx-auto text-left" onSubmit={handleSubmit}>
                            <Row className="justify-content-center">
                                <Col lg={12}>
                                    <Row className="mt-5">
                                        <Col md={6} className="mb-4 text-start">
                                            <Form.Label htmlFor="name" className='labelText'>ID DE CRÉDITO</Form.Label>
                                            <Form.Control
                                                type="text"
                                                bgwhite="true"
                                                name="name"
                                                value={code}
                                                onChange={e => setCreditId({ ...creditId, code: e.target.value })}
                                            />
                                        </Col>

                                        <Col md={6} className="mb-4 text-start">
                                            <Form.Group controlId="exampleForm.ControlSelect1">
                                                <Form.Label>ESTADO</Form.Label>
                                                <Form.Check
                                                    type="switch"
                                                    id="custom-switch"
                                                    label="Activar Id"
                                                    checked={isActive}
                                                    onChange={e => setCreditId({ ...creditId, isActive: e.target.checked })}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12} className="mb-4 text-start">
                                            <PrimaryButton type="submit" radius="3rem" font_size="1.6rem" className="btn-block w-100" disabled={loading}>
                                                {loading &&
                                                    <>
                                                        <Spinner
                                                            as="span"
                                                            animation="border"
                                                            size="sm"
                                                            role="status"
                                                            aria-hidden="true"
                                                            className='me-2'
                                                        />
                                                        <span className="visually-hidden">Loading...</span>
                                                    </>
                                                }
                                                Actualizar
                                            </PrimaryButton>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
            </Container>
        </DashboardStructure>
    );
}

export default Single;