import React from "react";
import { Container } from "react-bootstrap";
import Slider from "react-slick";
import { PrimaryButton } from '../../../components/general/Buttons';
// style
import styled from '@emotion/styled'
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import clienteAxios from "../../../config/Axios";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
const Message = () => {

    let token = useSelector(state => state.auth.token);

    const [messages, setMessages] = React.useState(null);

    let navigate = useNavigate()

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };


    React.useEffect(() => {
        clienteAxios.get('/api/messages/obtain-new-messages', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then(res => {
                console.log(res.data);
                setMessages(res.data);
            })
            .catch(err => {
                console.log(err);
            })
    }, [])

    const redirectTO = (message) => {
        navigate(`/mensajes/usuario/${message.from._id}`)
    }

    return (
        <div css={css`height:200px;`}>
            <Container>
                <Slider {...settings}>
                    {messages && messages.map((message, index) => (
                        <div key={index}>
                            <p className="mb-2"><span><b>Usuario:</b></span> {message.from.firstname}</p>
                            <p className="mb-2" css={css`
                            
                            `}><span><b>Mensaje:</b></span> {message.content.substring(0, 200) + "..."}</p>
                            <p className="mb-2"><span><b>fecha:</b></span> {message.createdAt.split('T')[0]}</p>
                            <PrimaryButton className="py-2" onClick={() => redirectTO(message)}>Ver mensaje</PrimaryButton>
                        </div>
                    ))}
                </Slider>
            </Container>
        </div>
    );
}

export default Message;