import {
    AUTH_START,
    AUTH_SUCCESS,
    AUTH_FAIL,

    LOGOUT_START,
    LOGOUT_SUCCESS,
    LOGOUT_FAIL,
} from './ActionsTypes';
// alerts
import Swal from 'sweetalert2';
// axios
import clienteAxios from '../../config/Axios';

export function LoginAction(formdata, navigate) {
    return (dispatch) => {
        dispatch(AuthStart())

        clienteAxios.post('/api/users/admin-signin', formdata)
            .then((res) => {
                console.log(res.data)
                dispatch(AuthSuccess(res.data))
                navigate('/dashboard')
            })
            .catch(error => {
                dispatch(AuthFail())
                if (!error.response) {
                    Swal.fire({ icon: 'error', title: 'Lo siento', text: 'Error de conexión.' })
                } else if (error.response.status === 400) {
                    Swal.fire({ icon: 'error', title: 'Lo siento', text: 'Usuario o contraseña incorrecta' })
                } else {
                    Swal.fire({ icon: 'error', title: 'Lo siento', text: 'Error, intente de nuevo' })
                }
            })
    }
}

export function LogoutAction(navigate, token) {
    return (dispatch) => {
        dispatch(LogoutStart())
        // console.log(token)
        const config = {
            headers: { 'Authorization': "Bearer " + token }
        };

        clienteAxios.post('/api/users/logout', {}, config)
            .then((res) => {
                dispatch(LogoutSuccess())
                navigate('/')
            })
            .catch(error => {
                dispatch(LogoutFail())
                if (!error.response) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Lo siento',
                        text: 'Error de conexión.'
                    })
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Lo siento',
                        text: 'Error'
                    })
                }
            })
    }
}



export const AuthStart = () => {
    return {
        type: AUTH_START
    };
};

export const AuthSuccess = (auth) => ({
    type: AUTH_SUCCESS,
    payload: auth
})

export const AuthFail = () => ({
    type: AUTH_FAIL,
})

// ------------------------------
export const LogoutStart = () => {
    return {
        type: LOGOUT_START
    };
};

export const LogoutSuccess = () => ({
    type: LOGOUT_SUCCESS,
})

export const LogoutFail = () => ({
    type: LOGOUT_FAIL,
})
// ------------------------------------------