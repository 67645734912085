//Bootstrap
import { Container, Row, Col } from 'react-bootstrap';
// style
import styled from '@emotion/styled'
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
// button
import { PrimaryButton } from './../../components/general/Buttons';
// images
import logo from '../../images/fioLogo.png';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { LogoutAction } from '../../redux/actions/Auth';
import { useDispatch, useSelector } from 'react-redux';

const ContainerGeneral = styled.div`
	@media (min-width:1200px){
	    /* position: absolute; */
		/* max-height: 85rem!important; */
	}
	@media (min-width:1200px){
		.closeMenu{
			display:none;
		}
		.openMenu{
			display:none;
		}
	}
`

const List = styled.ul`
  		li{
			overflow:hidden;
			color:#fff;
			text-align:center;
			margin-bottom:18px;
      cursor:pointer;
		}
`
const P = styled.p`
    text-align:center;
    text-decoration:none !important;
    color: #301C7B;
    font-size:.8rem;
`
const Navigation = ({ setMenu }) => {

    let { pathname } = useLocation();

    let token = useSelector(state => state.auth.token)
    const navigate = useNavigate()
    //DISPATCH
    const dispatch = useDispatch();
    //ACTIONS
    const logout = (navigate, token) => dispatch(LogoutAction(navigate, token));

    const logoutFn = () => {
        logout(navigate, token)
    }

    return (
        <ContainerGeneral className="d-flex w-100 h-100 flex-column justify-content-between text-center py-4">
            <div css={css`cursor:pointer;`}>
                <Link to="/dashboard">
                    <img src={logo} alt="FIO+ logo" className='img-fluid px-3' />
                </Link>
                <PrimaryButton className="closeMenu" onClick={() => setMenu(false)}>X</PrimaryButton>
            </div>

            <div>
                <List className="p-0 list-unstyled">
                    <li className="w-100 d-flex justify-content-center">
                        <Link to="/dashboard" className="text-decoration-none">
                            <svg width={40} css={css`fill:${pathname === '/dashboard' ? "#24FFDB" : "#301C7B"};`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                                <path d="M575.8 255.5C575.8 273.5 560.8 287.6 543.8 287.6H511.8L512.5 447.7C512.5 450.5 512.3 453.1 512 455.8V472C512 494.1 494.1 512 472 512H456C454.9 512 453.8 511.1 452.7 511.9C451.3 511.1 449.9 512 448.5 512H392C369.9 512 352 494.1 352 472V384C352 366.3 337.7 352 320 352H256C238.3 352 224 366.3 224 384V472C224 494.1 206.1 512 184 512H128.1C126.6 512 125.1 511.9 123.6 511.8C122.4 511.9 121.2 512 120 512H104C81.91 512 64 494.1 64 472V360C64 359.1 64.03 358.1 64.09 357.2V287.6H32.05C14.02 287.6 0 273.5 0 255.5C0 246.5 3.004 238.5 10.01 231.5L266.4 8.016C273.4 1.002 281.4 0 288.4 0C295.4 0 303.4 2.004 309.5 7.014L564.8 231.5C572.8 238.5 576.9 246.5 575.8 255.5L575.8 255.5z" />
                            </svg>
                            <P className='mt-1'>Dashboard</P>
                        </Link>
                    </li>
                    <li className="w-100 d-flex justify-content-center">
                        <Link to="/usuarios" className="text-decoration-none">
                            <svg width={40} css={css`fill:${pathname.includes('/usuarios') ? "#24FFDB" : "#301C7B"};`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                <path d="M256 288c79.53 0 144-64.47 144-144s-64.47-144-144-144c-79.52 0-144 64.47-144 144S176.5 288 256 288zM351.1 320H160c-88.36 0-160 71.63-160 160c0 17.67 14.33 32 31.1 32H480c17.67 0 31.1-14.33 31.1-32C512 391.6 440.4 320 351.1 320z" />
                            </svg>
                            <P className='mt-1'>Usuarios</P>
                        </Link>
                    </li>
                    <li className="w-100 d-flex justify-content-center">
                        <Link to="/mensajes" className="text-decoration-none">
                            <svg width={40} css={css`fill:${pathname.includes('/mensajes') ? "#24FFDB" : "#301C7B"};`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                <path d="M493.6 163c-24.88-19.62-45.5-35.37-164.3-121.6C312.7 29.21 279.7 0 256.4 0H255.6C232.3 0 199.3 29.21 182.6 41.38c-118.8 86.25-139.4 101.1-164.3 121.6C6.75 172 0 186 0 200.8v263.2C0 490.5 21.49 512 48 512h416c26.51 0 48-21.49 48-47.1V200.8C512 186 505.3 172 493.6 163zM303.2 367.5C289.1 378.5 272.5 384 256 384s-33.06-5.484-47.16-16.47L64 254.9V208.5c21.16-16.59 46.48-35.66 156.4-115.5c3.18-2.328 6.891-5.187 10.98-8.353C236.9 80.44 247.8 71.97 256 66.84c8.207 5.131 19.14 13.6 24.61 17.84c4.09 3.166 7.801 6.027 11.15 8.478C400.9 172.5 426.6 191.7 448 208.5v46.32L303.2 367.5z" />
                            </svg>
                            <P className='mt-1'>Mensajes</P>
                        </Link>
                    </li>
                    <li className="w-100 d-flex justify-content-center">
                        <Link to="/oportunidades" className="text-decoration-none">
                            <svg width={40} css={css`fill:${pathname.includes('/oportunidades') || pathname.includes('/oportunidad') ? "#24FFDB" : "#301C7B"};`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                                <path d="M147.8 192H480V144C480 117.5 458.5 96 432 96h-160l-64-64h-160C21.49 32 0 53.49 0 80v328.4l90.54-181.1C101.4 205.6 123.4 192 147.8 192zM543.1 224H147.8C135.7 224 124.6 230.8 119.2 241.7L0 480h447.1c12.12 0 23.2-6.852 28.62-17.69l96-192C583.2 249 567.7 224 543.1 224z" />
                            </svg>
                            <P className='mt-1'>Oportunidades</P>
                        </Link>
                    </li>
                    <li className="w-100 d-flex justify-content-center">
                        <Link to="/sliders" className="text-decoration-none">
                            <svg width={40} css={css`fill:${pathname.includes('/sliders') || pathname.includes('/slider') ? "#24FFDB" : "#301C7B"};`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                                <path d="M528 32H144c-26.51 0-48 21.49-48 48v256c0 26.51 21.49 48 48 48H528c26.51 0 48-21.49 48-48v-256C576 53.49 554.5 32 528 32zM223.1 96c17.68 0 32 14.33 32 32S241.7 160 223.1 160c-17.67 0-32-14.33-32-32S206.3 96 223.1 96zM494.1 311.6C491.3 316.8 485.9 320 480 320H192c-6.023 0-11.53-3.379-14.26-8.75c-2.73-5.367-2.215-11.81 1.332-16.68l70-96C252.1 194.4 256.9 192 262 192c5.111 0 9.916 2.441 12.93 6.574l22.35 30.66l62.74-94.11C362.1 130.7 367.1 128 373.3 128c5.348 0 10.34 2.672 13.31 7.125l106.7 160C496.6 300 496.9 306.3 494.1 311.6zM456 432H120c-39.7 0-72-32.3-72-72v-240C48 106.8 37.25 96 24 96S0 106.8 0 120v240C0 426.2 53.83 480 120 480h336c13.25 0 24-10.75 24-24S469.3 432 456 432z" />
                            </svg>
                            <P className='mt-1'>Sliders</P>
                        </Link>
                    </li>
                    <li className="w-100 d-flex justify-content-center">
                        <Link to="/ids-creditos" className="text-decoration-none">
                            <svg width={40} css={css`fill:${pathname.includes('/ids-creditos') || pathname.includes('/ids-creditos') ? "#24FFDB" : "#301C7B"};`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                                <path d="M170.8 280H213.2L192 237.7L170.8 280zM512 32H64C28.65 32 0 60.65 0 96v320c0 35.35 28.65 64 64 64h448c35.35 0 64-28.65 64-64V96C576 60.65 547.3 32 512 32zM274.7 349.5C271.3 351.2 267.6 352 264 352c-8.812 0-17.28-4.859-21.5-13.27L233.2 320H150.8l-9.367 18.73c-5.906 11.86-20.31 16.7-32.19 10.73c-11.88-5.938-16.69-20.34-10.75-32.2l72-144c8.125-16.25 34.81-16.25 42.94 0l72 144C291.4 329.1 286.6 343.5 274.7 349.5zM384 352h-56c-13.25 0-24-10.75-24-24v-144C304 170.8 314.8 160 328 160H384c52.94 0 96 43.06 96 96S436.9 352 384 352zM384 208h-32v96h32c26.47 0 48-21.53 48-48S410.5 208 384 208z" />
                            </svg>
                            <P className='mt-1'>ID Crédito</P>
                        </Link>
                    </li>
                </List>
            </div>

            <div css={css`cursor:pointer;`} >
                <Link to="#" onClick={() => logoutFn()}>
                    <svg width={40} css={css`fill: #301C7B;`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                        <path d="M160 416H96c-17.67 0-32-14.33-32-32V128c0-17.67 14.33-32 32-32h64c17.67 0 32-14.33 32-32S177.7 32 160 32H96C42.98 32 0 74.98 0 128v256c0 53.02 42.98 96 96 96h64c17.67 0 32-14.33 32-32S177.7 416 160 416zM502.6 233.4l-128-128c-12.51-12.51-32.76-12.49-45.25 0c-12.5 12.5-12.5 32.75 0 45.25L402.8 224H192C174.3 224 160 238.3 160 256s14.31 32 32 32h210.8l-73.38 73.38c-12.5 12.5-12.5 32.75 0 45.25s32.75 12.5 45.25 0l128-128C515.1 266.1 515.1 245.9 502.6 233.4z" />
                    </svg>
                </Link>
            </div>
        </ContainerGeneral>
    );
}

export default Navigation;