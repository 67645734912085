import {
    NEW_SLIDER_START,
    NEW_SLIDER_SUCCESS,
    NEW_SLIDER_FAIL,
} from './ActionsTypes';
// alerts
import Swal from 'sweetalert2';
// axios
import clienteAxios from '../../config/Axios';
import axios from 'axios';

export function NewSliderAction(body, navigate, token, onHide, setFormData, update, setUpdate) {
    return (dispatch) => {
        dispatch(NewSliderStart())

        const config = {
            headers: {
                'Authorization': "Bearer " + token,
            }
        };

        clienteAxios.post('/api/sliders/new-slider', body, config)
            .then((res) => {
                // console.log(res.data)
                Swal.fire({ icon: 'success', title: 'Exito', text: 'Slider creado con éxito' })
                dispatch(NewSliderSuccess())
                setFormData({
                    title: "",
                    subtitle: "",
                    content: "",
                    oportunityId: "",
                    image: "",
                })
                setUpdate(update + 1)
                onHide()
            })
            .catch(error => {
                dispatch(NewSliderFail())
                console.log(error.response)
                if (!error.response) {
                    Swal.fire({ icon: 'error', title: 'Lo siento', text: 'Error de conexión.' })
                } else if (error.response.status === 400) {
                    Swal.fire({ icon: 'error', title: 'Lo siento', text: 'Datos invalidos' })
                } else {
                    Swal.fire({ icon: 'error', title: 'Lo siento', text: 'Error, intente de nuevo' })
                }
            })
    }
}



export const NewSliderStart = () => {
    return {
        type: NEW_SLIDER_START
    };
};

export const NewSliderSuccess = () => ({
    type: NEW_SLIDER_SUCCESS
})

export const NewSliderFail = () => ({
    type: NEW_SLIDER_FAIL,
})
