// import { BrowserRouter, Routes, Route } from "react-router-dom";
import { HashRouter, Routes, Route } from "react-router-dom";
//-----
import { Provider, useSelector } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { store, persistor } from './redux/state/createStore';
import { SocketProvider } from './context/SocketContext';
import { ChatProvider } from './context/chat/ChatContext';
// pages
import Login from './components/pages/login/index'
import Dashboard from './components/pages/dashboard/index'
import Users from './components/pages/users/index'
import SingleUser from './components/pages/users/single/index'
import Messages from './components/pages/messages'
import SingleMessages from './components/pages/messages/single/index'
import Opportunities from './components/pages/opportunities/index'
import SingleOppotunities from './components/pages/opportunities/single/index'
import Sliders from './components/pages/sliders/index'
import SingleSlider from './components/pages/sliders/single/index'
import CreditsIds from './components/pages/creditsIds/index'
import CreditsIdsSingle from './components/pages/creditsIds/single/index'
import NotFound from './components/pages/404/index'

import moment from 'moment';
import 'moment/locale/es';
moment.locale('es');

function App() {
  return (
    <HashRouter>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ChatProvider>
            <SocketProvider>
              <Routes>
                <Route path="/" element={<Login />} />
                <Route path="dashboard" element={<Dashboard />} />
                <Route path="usuarios" element={<Users />} />
                <Route path="usuarios/:id" element={<SingleUser />} />
                <Route path="mensajes" element={<Messages />} />
                <Route path="mensajes/usuario/:id" element={<SingleMessages />} />
                <Route path="oportunidades" element={<Opportunities />} />
                <Route path="oportunidad/:id" element={<SingleOppotunities />} />
                <Route path="sliders" element={<Sliders />} />
                <Route path="slider/:id" element={<SingleSlider />} />
                <Route path="ids-creditos" element={<CreditsIds />} />
                <Route path="ids-creditos/:id" element={<CreditsIdsSingle />} />
                <Route path="*" element={<NotFound />} />
              </Routes>
            </SocketProvider>
          </ChatProvider>
        </PersistGate>
      </Provider>
    </HashRouter>
  )
}
export default App;