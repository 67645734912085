import React, { useState, useEffect, useContext } from 'react';
// bootstrap
import { Container } from 'react-bootstrap';
// style
import styled from '@emotion/styled'
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
// dashboardstrcture
import DashboardStructure from '../../general/DashboardStructure';
import {
    SearchState,
    IntegratedFiltering,
    SelectionState,
    IntegratedSelection,
} from '@devexpress/dx-react-grid';
import {
    Grid,
    Table,
    Toolbar,
    SearchPanel,
    TableHeaderRow,
    TableSelection,
} from '@devexpress/dx-react-grid-bootstrap4';
import { useNavigate } from 'react-router-dom';
import { PrimaryButton } from '../../general/Buttons';
import ModalMessages from './modal';
import clienteAxios from '../../../config/Axios';
import { useSelector } from 'react-redux';
import { types } from '../../../types/types';
import { ChatContext } from '../../../context/chat/ChatContext';
import ModalPush from './push';

const Create = styled.div`
    position:absolute;
    top:10px;
    right:15px;
    button{
        z-index:99;
    }
`

const TableRow = ({ row, ...restProps }) => {
    const navigate = useNavigate()
    return (
        <Table.Row
            {...restProps}
            // eslint-disable-next-line no-alert
            onClick={() => navigate(`/mensajes/usuario/${row.id}`)}
            style={{
                cursor: 'pointer',
            }}
        />
    )
}

const HighlightedCell = ({ value, style, ...restProps }) => (
    <Table.Cell>
        <span
            style={{
                color: value === "Activo" ? 'green' : "red",
                fontWeight: 'bolder',
            }}
        >
            {value}
        </span>
    </Table.Cell>
);
const HighlightedCell2 = ({ value, style, ...restProps }) => (
    <Table.Cell>
        <span
            style={{
                color: value === "Cliente" ? '#301C7B' : "#919090",
                fontWeight: 'bolder',
            }}
        >
            {value}
        </span>
    </Table.Cell>
);

const Cell = (props) => {
    const { column } = props;
    if (column.name === 'status') {
        return <HighlightedCell {...props} />;
    }
    if (column.name === 'role') {
        return <HighlightedCell2 {...props} />;
    }
    return <Table.Cell {...props} />;
};

const Users = () => {

    let token = useSelector(state => state.auth.token);
    let userId = useSelector(state => state.auth.userId);

    const [selection, setSelection] = useState([]);
    const [modalShow, setModalShow] = useState(false);
    const [modalShow2, setModalShow2] = useState(false);
    const [rows, setRows] = useState([]);
    const [usersSelectedIds, setUsersSelectedIds] = useState(null);

    const { dispatch } = useContext(ChatContext);

    const [columns] = useState([
        { name: 'name', title: 'Nombre' },
        { name: 'lastname', title: 'Apellido' },
        { name: 'date', title: 'Fecha de inscripción' },
        { name: 'status', title: 'Estado' },
        { name: 'role', title: 'Tipo de usuario' },
    ]);


    React.useEffect(() => {

        let usersSelected = rows.filter((user, i) => selection.includes(i));
        let obtaiUsersIds = usersSelected.map(user => user.id);

        console.log(obtaiUsersIds);
        setUsersSelectedIds(obtaiUsersIds)

    }, [selection])



    React.useEffect(() => {
        const config = { headers: { 'Authorization': "Bearer " + token } };//confi axios

        clienteAxios.get('/api/users/obtain-users', config)
            .then((res) => {
                console.log(res.data)

                let data = []
                for (let i = 0; i < res.data.length; i++) {
                    const element = res.data[i];

                    data.push({
                        name: element.firstname,
                        lastname: element.lastname,
                        date: element.createdAt.split('T')[0],
                        status: element.isActive ? "Activo" : "Inactivo",
                        role: element.creditIDId ? element.creditIDId.isActive ? "Cliente" : "Prospecto" : "Prospecto",
                        id: element._id
                    })
                }

                setRows(data)
            })
            .catch(error => {
                console.log(error.response)
            })
    }, [])

    useEffect(() => {
        dispatch({
            type: types.cleanMessages
        })
    }, [])

    return (
        <DashboardStructure asideRight titlePage="Mensajes - Usuarios">
            <Container fluid className="p-3 h-100">
                <div className="card h-100">
                    <Grid
                        rows={rows}
                        columns={columns}
                    >
                        <SelectionState
                            selection={selection}
                            onSelectionChange={setSelection}
                        />
                        <SearchState />
                        <IntegratedFiltering />
                        <IntegratedSelection />
                        <Table rowComponent={TableRow} cellComponent={Cell} />
                        <TableHeaderRow />
                        <TableSelection showSelectAll />
                        <Toolbar />
                        <Create>
                            <PrimaryButton disabled={selection.length > 0 ? false : true} onClick={() => setModalShow(true)}>Enviar mensajes masivos</PrimaryButton>
                            <PrimaryButton className='ms-3' disabled={selection.length > 0 ? false : true} onClick={() => setModalShow2(true)}>Enviar Push masivos</PrimaryButton>
                        </Create>
                        <SearchPanel />
                    </Grid>
                </div>
                <ModalMessages
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                    from={userId}
                    to={usersSelectedIds}
                />
                <ModalPush
                    show={modalShow2}
                    onHide={() => setModalShow2(false)}
                    from={userId}
                    to={usersSelectedIds}
                />
            </Container >
        </DashboardStructure >
    );
}

export default Users;