import React, { useState, useEffect, useContext } from 'react';
// style
import styled from '@emotion/styled'
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'

// dashboardstrcture
import { PrimaryButton } from './Buttons';
import { SocketContext } from '../../context/SocketContext';
import { scrollToBottonAnimated } from '../../helpers/scrollToBotton';
import Swal from 'sweetalert2';
import clienteAxios from '../../config/Axios';
import { useSelector } from 'react-redux';
import { Badge, Spinner } from 'react-bootstrap';

// import { AuthContext } from "../auth/AuthContext";
// import { ChatContext } from "../context/chat/ChatContext";
// import { SocketContext } from "../context/SocketContext";

const SendMessageForm = styled.div`
	position:relative;
	.badgefiles{
		position:absolute;
		top:-15px;
		margin:0 10px;
	}
    .send-message-form {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 10px;
        border-bottom: 1px solid #ccc;
    }

    .send-message-button {
        margin-left: 10px;
    }
    .message-input{
    background: #f2f2f2; ;
    border-radius: 20px;
    padding: 10px 20px;

}
textarea {
        width: calc(100% - 100px);
        padding: 10px;
        border: none;
        border-radius: 5px;
        font-size: 1.2rem;
        resize: none;
    }

    textarea:focus {
        outline: none;
    }

    textarea::placeholder {
        color: #ccc;
    }
`

export const SendMessage = ({ from, to }) => {
	let token = useSelector(state => state.auth.token);

	const [message, setMessage] = useState('');
	const [files, setFiles] = useState([]);
	const [loadingFiles, setLoadingFiles] = useState(false);
	const { socket } = useContext(SocketContext);
	// const { auth } = useContext(AuthContext);
	// const { chatState } = useContext(ChatContext);

	const onChange = ({ target }) => {
		setMessage(target.value);
	}

	const onSubmit = (e) => {
		e.preventDefault();
		if (message.length === 0) { return; }

		setMessage('');
		setFiles([]);

		// console.log('send message', message);

		if (files.length > 0) {
			socket.emit('newMessage', {
				from,
				to,
				content: message,
				files
			}, (response) => {
				console.log(response);
			});
		} else {
			socket.emit('newMessage', {
				from,
				to,
				content: message
				// files: null, // Carlos dice que no se envia si no hay archivo
			}, (response) => {
				console.log(response);
			});
		}

		let body = {
			"subject": "Mensaje FIO+",
			"message": message,
			"receiverId": to
		}

		// setTimeout(() => {
		const config = { headers: { 'Authorization': "Bearer " + token } };//confi axios
		clienteAxios.post(`/api/notifications/new-push-notification`, body, config)
			.then((res) => {
				console.log(res)
			})
			.catch(error => {
				console.log(error.response)
			})

		setTimeout(() => {
			scrollToBottonAnimated('chat');
		}, 300)
	}

	const AddFiles = async () => {
		const { value: files } = await Swal.fire({
			title: 'Seleccionar archivos',
			input: 'file',
			inputAttributes: {
				'accept': 'application/pdf,.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel,image/jpeg, image/png, image/gif',
				'aria-label': 'Seleccionar archivos',
				'multiple': true
			}
		})

		if (files) {
			setLoadingFiles(true)
			// console.log(file)
			let data = Object.values(files)

			const formData = new FormData();
			data.forEach(file => {
				formData.append("files", file);
			});

			clienteAxios.post('/api/messages/upload-message-files', formData, {
				headers: {
					"Authorization": `Bearer ${token}`,
					"Content-Type": "multipart/form-data"
				}
			})
				.then(res => {
					setLoadingFiles(false)
					console.log(res)

					setFiles(res.data);
				})
				.catch(err => {
					setLoadingFiles(false)
					console.log(err)
				})

		}
	}


	return (
		<SendMessageForm className='shadow-lg'>

			<div className="position-absolute d-flex badgefiles">
				{files.map((file, index) => {
					return (
						<Badge pill bg="secondary" className='mx-2'>
							{file.name}
						</Badge>
					)
				})}
			</div>

			<form
				className="send-message-form"
				onSubmit={onSubmit}
			>
				<textarea
					placeholder="Escriba su mensaje..."
					className='message-input'
					onChange={onChange}
					value={message}
					rows="1"
				/>
				{loadingFiles ?
					<div className='btn bt-light'>
						<Spinner
							as="span"
							animation="border"
							size="sm"
							role="status"
							aria-hidden="true"
							className='me-2'
						/>
						<span className="visually-hidden">Loading...</span>
					</div>
					:
					<div className='btn bt-light' onClick={() => AddFiles()} >
						<svg width={"20"} css={css`fill:#ccc;`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
							<path d="M364.2 83.8C339.8 59.39 300.2 59.39 275.8 83.8L91.8 267.8C49.71 309.9 49.71 378.1 91.8 420.2C133.9 462.3 202.1 462.3 244.2 420.2L396.2 268.2C407.1 257.3 424.9 257.3 435.8 268.2C446.7 279.1 446.7 296.9 435.8 307.8L283.8 459.8C219.8 523.8 116.2 523.8 52.2 459.8C-11.75 395.8-11.75 292.2 52.2 228.2L236.2 44.2C282.5-2.08 357.5-2.08 403.8 44.2C450.1 90.48 450.1 165.5 403.8 211.8L227.8 387.8C199.2 416.4 152.8 416.4 124.2 387.8C95.59 359.2 95.59 312.8 124.2 284.2L268.2 140.2C279.1 129.3 296.9 129.3 307.8 140.2C318.7 151.1 318.7 168.9 307.8 179.8L163.8 323.8C157.1 330.5 157.1 341.5 163.8 348.2C170.5 354.9 181.5 354.9 188.2 348.2L364.2 172.2C388.6 147.8 388.6 108.2 364.2 83.8V83.8z" />
						</svg>
					</div>
				}
				<PrimaryButton
					className="send-message-button"
					type="submit">
					Enviar
				</PrimaryButton>
			</form>
		</SendMessageForm>
	)
}