import React, { useState, useContext, useEffect } from 'react';
import { PrimaryButton } from '../../general/Buttons';
// bootstrap
import { Container, Row, Col, Form, Button, Modal, ProgressBar } from 'react-bootstrap';
// style
import styled from '@emotion/styled'
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { SocketContext } from '../../../context/SocketContext';
import Swal from 'sweetalert2';
import { useSelector } from 'react-redux';
import clienteAxios from '../../../config/Axios';

const FormGeneral = styled(Form)`
    label{
        font-weight:500;
    }
`

const ModalPush = (props) => {
    let token = useSelector(state => state.auth.token)

    const [to, setTo] = useState();
    const [from, setFrom] = useState();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setTo(props.to)
        setFrom(props.from)
    }, [props])


    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');
    const [countState, setCount] = useState(0);
    // const { socket } = useContext(SocketContext);


    const onChange = ({ target }) => {
        setSubject(target.value);
    }
    const onChange2 = ({ target }) => {
        setMessage(target.value);
    }

    const onSubmit = async (e) => {

        setLoading(true);

        e.preventDefault();

        console.log(to, from)

        const config = { headers: { 'Authorization': "Bearer " + token } };//confi axios


        let count = 1;
        // total x porcentaje / 100

        for (let i = 0; i < to.length; i++) {
            const id = to[i];

            let body = {
                "subject": subject,
                "message": message,
                "receiverId": id
            }

            // setTimeout(() => {
            // console.log("1")
            await clienteAxios.post(`/api/notifications/new-push-notification`, body, config)
                .then((res) => {
                    console.log(res)
                    setCount(count++)
                    // Swal.fire({
                    //     title: 'Notificación enviada',
                    //     text: 'La notificación ha sido enviada correctamente',
                    //     icon: 'success',
                    //     showConfirmButton: false,
                    //     timer: 1500
                    // })
                })
                .catch(error => {
                    console.log(error.response)
                    // Swal.fire({
                    //     title: 'Error',
                    //     text: 'Ha ocurrido un error',
                    //     icon: 'error',
                    //     showConfirmButton: false,
                    //     timer: 1500
                    // })
                })
            // }, 2000 * i);

        }

        Swal.fire({
            title: 'Push enviadas',
            text: 'Las Push han sido enviada correctamente',
            icon: 'success',
            showConfirmButton: false,
            timer: 1500
        })
        setSubject('');
        setMessage('')
        
        setTimeout(() => {
            setLoading(false);
            setCount(0)
        }, 3000);
        // props.onHide()

    }


    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <FormGeneral onSubmit={onSubmit}>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Push Masivos
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className='my-5'>
                        <Col xs={12}>
                            <Row className='mt-4'>
                                <Form.Group as={Col} md={12} controlId="exampleForm.ControlSelect1">
                                    <Form.Label>ASUNTO</Form.Label>
                                    <Form.Control className="mb-3" onChange={onChange} value={subject}>
                                    </Form.Control>
                                </Form.Group>
                            </Row>
                        </Col>
                        <Col xs={12}>
                            <Row className='mt-4'>
                                <Form.Group as={Col} md={12} controlId="exampleForm.ControlSelect1">
                                    <Form.Label>MENSAJE</Form.Label>
                                    <Form.Control as={"textarea"} rows="6" className="mb-3" onChange={onChange2} value={message}>
                                    </Form.Control>
                                </Form.Group>
                            </Row>
                        </Col>
                    </Row>
                    {loading && <ProgressBar animated now={countState} max={to ? to.length : 0} />}
                    
                </Modal.Body>
                <Modal.Footer>
                    <PrimaryButton type="submit" variant="secondary">Enviar</PrimaryButton>
                    <PrimaryButton type='button' onClick={props.onHide}>Cerrar</PrimaryButton>
                </Modal.Footer>
            </FormGeneral>
        </Modal>
    );
}

export default ModalPush