import React, { useState, useEffect } from 'react';
//Bootstrap
import { Container, Row, Col, Form } from 'react-bootstrap';
// style
import styled from '@emotion/styled'
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
// button
import { PrimaryButton } from './../general/Buttons';
// navegation
import Navigation from './Navegation'

import Push from 'push.js';

const Section = styled.section`
	background: #F5F5F5;
	overflow-x:hidden;
	height: 100%;
	max-height:100%;
	border-radius:15px;
	width:100%;
	margin:auto;
	position:relative;
	transition:all .2s !important;
	@media (max-width:1200px){
		.openMenu{
			display:flex;
		}
	}
	@media (min-width:1300px){
		.closeNotifications{
			display:none;
		}
		.openNotifications{
			display:none;
		}
	}
	@media (min-width:1200px){
		.closeMenu{
			display:none;
		}
		.openMenu{
			display:none;
		}
	}
	.menu{
		border-right: 1px solid #2E3336;
		transition:all .2s !important;
		z-index:10;
		min-height:100vh;
		box-sizing: border-box;
		-moz-box-sizing: border-box;
		-webkit-box-sizing: border-box;
		@media (max-width:1200px){
			position:fixed;
			top:0;
			left:0;
			bottom:0;
			width:10rem;
			background-color:#fff;
			transition:all .2s !important;
			padding:0 0rem !important;
		}
	}
`;

const WelcomeText = styled.p`
	font-size: 2rem;
`;

const Title = styled.h1`
	font-size: 2rem;
`;

const Heading2 = styled.h2`
	font-size: 2rem;
	margin-top:0;
`;

const Select = styled(Form.Control)`
	width:300px;
  color:  #000 !important;
  border-radius: 6px;
  height:5rem;
  font-size:1.6rem;
  border:none;
  border: 2px solid #2E3336;
  &:focus {
    flex:1;
    color: #000 !important;
    border: 2px solid #2E3336 !important;
    border-radius: 6px;
		height:5rem;
    font-size:1.6rem;
    box-shadow: none;
  }
`;

const MenuLeft = styled.div`
	width:8vw !important;
	padding-right: 0;
	position:relative;
	transition:all .2s !important;
	position: fixed;
	height: 100vh !important;
	@media(max-width:1400px){
		width:10vw !important;
	}
	@media(max-width:1200px){
		width:8rem !important;
	}
`
const Main = styled.div`
	width:92vw !important;
	margin-left:8vw;
	min-height:100vh;
	@media(max-width:1400px){
		margin-left:10vw;
	}
	@media(max-width:1200px){
		margin-left:0;
		width:100vw !important;
	}
`

const DashboardStructure = ({ children, titlePage }) => {
	const [notification, setNotification] = useState(false)
	const [menu, setMenu] = useState(false)

	useEffect(() => {
		Push.Permission.request();
	}, [])

	return (
		<Section>
			<Container fluid className="h-100 w-100  px-0">
				<Row className="h-100 d-flex">
					<MenuLeft css={css`@media (max-width:1200px){transform:${menu ? "translateX(0%)" : "translateX(-99.99%)"};}`} className="menu">
						<Navigation setMenu={setMenu} />
					</MenuLeft>
					<Main>
						<Container fluid css={css`border-bottom: 1px solid #2E3336;max-width:100% !important; padding:0px;`}>
							<Row className="justify-content-between align-items-center py-4 d-flex w-100 m-0">
								<Col lg={6} md={6} sm={12} className="d-flex align-items-center" css={css`
									@media (max-width:768px){
										justify-content:center;
									}
								`}>
									<PrimaryButton className="openMenu px-3 py-2 me-3" onClick={() => setMenu(true)}>
										<svg width={'20'} fill="#fff" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
											<path d="M0 96C0 78.33 14.33 64 32 64H416C433.7 64 448 78.33 448 96C448 113.7 433.7 128 416 128H32C14.33 128 0 113.7 0 96zM0 256C0 238.3 14.33 224 32 224H416C433.7 224 448 238.3 448 256C448 273.7 433.7 288 416 288H32C14.33 288 0 273.7 0 256zM416 448H32C14.33 448 0 433.7 0 416C0 398.3 14.33 384 32 384H416C433.7 384 448 398.3 448 416C448 433.7 433.7 448 416 448z" />
										</svg>
									</PrimaryButton>
									<Title className="mb-0">{titlePage}</Title>
								</Col>
								<Col lg={6} md={6} sm={12}>
									<div className="d-flex align-items-center text-right" css={css`
										justify-content: flex-end;
										@media (max-width:768px){
											justify-content:center;
										}
									`}>
										<WelcomeText className="mb-0 mr-4">Bienvenido a <strong>FIO+</strong></WelcomeText>
									</div>
								</Col>
							</Row>
						</Container>
						<div css={css`position:relative; height:89%;`}>
							{children}
						</div>
					</Main>
				</Row>
			</Container>
		</Section>
	);
}

export default DashboardStructure;