import React, { useState, useEffect } from 'react';
// bootstrap
import { Container } from 'react-bootstrap';
// style
import styled from '@emotion/styled'
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
// dashboardstrcture
import DashboardStructure from '../../general/DashboardStructure';
import {
    SearchState,
    IntegratedFiltering,
} from '@devexpress/dx-react-grid';
import {
    Grid,
    Table,
    Toolbar,
    SearchPanel,
    TableHeaderRow,
} from '@devexpress/dx-react-grid-bootstrap4';
// navitaion
import { useNavigate } from 'react-router-dom';
// button
import { PrimaryButton } from '../../general/Buttons';
// modal
import CreateUser from './modal';
import clienteAxios from '../../../config/Axios';
import { useSelector } from 'react-redux';



const Create = styled.div`
    position:absolute;
    top:10px;
    right:15px;
    button{
        z-index:99;
    }
`

const TableRow = ({ row, ...restProps }) => {
    const navigate = useNavigate()
    return (
        <Table.Row
            {...restProps}
            // eslint-disable-next-line no-alert
            onClick={() => navigate(`/usuarios/${row.id}`)}
            style={{
                cursor: 'pointer',
            }}
        />
    )
}

const HighlightedCell = ({ value, style, ...restProps }) => (
    <Table.Cell>
        <span
            style={{
                color: value === "Activo" ? 'green' : "red",
                fontWeight: 'bolder',
            }}
        >
            {value}
        </span>
    </Table.Cell>
);
const HighlightedCell2 = ({ value, style, ...restProps }) => (
    <Table.Cell>
        <span
            style={{
                color: value === "Cliente" ? '#301C7B' : "#919090",
                fontWeight: 'bolder',
            }}
        >
            {value}
        </span>
    </Table.Cell>
);

const Cell = (props) => {
    const { column } = props;
    if (column.name === 'status') {
        return <HighlightedCell {...props} />;
    }
    if (column.name === 'role') {
        return <HighlightedCell2 {...props} />;
    }
    return <Table.Cell {...props} />;
};

const Users = () => {
    let token = useSelector(state => state.auth.token)

    const [rows, setRows] = useState([]);
    const [update, setUpdate] = useState(1);
    const [modalShow, setModalShow] = useState(false);

    const [columns] = useState([
        { name: 'name', title: 'Nombre' },
        { name: 'lastname', title: 'Apellido' },
        { name: 'date', title: 'Fecha de inscripción' },
        { name: 'status', title: 'Estado' },
        { name: 'role', title: 'Tipo de usuario' },
    ]);


    useEffect(() => {
        const config = { headers: { 'Authorization': "Bearer " + token } };//confi axios

        clienteAxios.get('/api/users/obtain-users', config)
            .then((res) => {
                console.log(res.data)

                let data = []
                for (let i = 0; i < res.data.length; i++) {
                    const element = res.data[i];

                    data.push({
                        name: element.firstname,
                        lastname: element.lastname,
                        date: element.createdAt.split('T')[0],
                        status: element.isActive ? "Activo" : "Inactivo",
                        role: element.creditIDId ? element.creditIDId.isActive ? "Cliente" : "Prospecto" : "Prospecto",
                        // role: element.creditIDId.isActive ? "Cliente" : "Prospecto",
                        id: element._id
                    })
                }

                setRows(data)
            })
            .catch(error => {
                console.log(error.response)
            })
    }, [update])



    return (
        <DashboardStructure asideRight titlePage="Usuarios">
            <Container fluid className="p-3 h-100">
                <div className="card h-100">
                    <Grid
                        rows={rows}
                        columns={columns}
                    >
                        <SearchState />
                        <IntegratedFiltering />
                        <Table rowComponent={TableRow} cellComponent={Cell} />
                        <TableHeaderRow />
                        <Toolbar />
                        <Create>
                            <PrimaryButton onClick={() => setModalShow(true)}>Crear usuario</PrimaryButton>
                        </Create>
                        <SearchPanel />
                    </Grid>
                </div>
            </Container>
            <CreateUser
                show={modalShow}
                onHide={() => setModalShow(false)}
                update={update}
                setUpdate={setUpdate}
            />
        </DashboardStructure>
    );
}

export default Users;