import React, { useState, useEffect } from 'react';
import { PrimaryButton } from '../../general/Buttons';
// bootstrap
import { Container, Row, Col, Form, Button, Modal, Spinner } from 'react-bootstrap';
// style
import styled from '@emotion/styled'
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import Swal from 'sweetalert2';
// navigation
import { useNavigate } from 'react-router-dom';
// redux
import { useDispatch, useSelector } from 'react-redux';
import clienteAxios from '../../../config/Axios';
//Actions
import { NewSliderAction } from '../../../redux/actions/NewSlider';

const ModalCreate = (props) => {

    let token = useSelector(state => state.auth.token);
    let loading = useSelector(state => state.newSlider.loading);

    const navigate = useNavigate()
    //DISPATCH
    const dispatch = useDispatch();
    //ACTIONS
    const NewSliderFn = (body, navigate, token, onHide, setFormData, update, setUpdate) => dispatch(NewSliderAction(body, navigate, token, onHide, setFormData, update, setUpdate));

    const [opportunities, setOpportunities] = useState([])

    const [formdata, setFormData] = useState({
        title: "",
        subtitle: "",
        content: "",
        oportunityId: "",
        image: "",
    })

    const { title, subtitle, content, image } = formdata;

    const readFormData = (e) => {
        setFormData({
            ...formdata,
            [e.target.name]: e.target.value
        })
    }


    useEffect(() => {

        clienteAxios.get(`/api/oportunities/obtain-oportunities-by-admin`, { headers: { 'Authorization': "Bearer " + token } })
            .then((res) => {
                console.log(res.data)
                setOpportunities(res.data)
            })
            .catch(error => {
                console.log(error.response)
            })


    }, [])


    const handleSubmit = (e) => {
        e.preventDefault();

        if (title === "") {
            Swal.fire({ icon: 'error', title: 'Oops...', text: 'El campo titulo es requerido' })
        } else if (subtitle === "") {
            Swal.fire({ icon: 'error', title: 'Oops...', text: 'El campo subtitulo es requerido' })
        } else if (content === "") {
            Swal.fire({ icon: 'error', title: 'Oops...', text: 'El campo contenido es requerido' })
        } else {
            console.log(formdata)

            let data = new FormData();
            data.append('title', title);
            data.append('subtitle', subtitle);
            data.append('content', content);
            data.append('image', image);

            if (formdata.oportunityId !== "") {
                data.append('oportunityId', formdata.oportunityId);
            }

            NewSliderFn(data, navigate, token, props.onHide, setFormData, props.update, props.setUpdate)


        }
    }


    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            css={css`.form-label{font-weight:500;}`}
        >
            <Form onSubmit={handleSubmit}>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Crear Slider
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className='my-5'>
                        <Col xs={12}>
                            <h4>DATOS</h4>
                            <div className='mt-4'>
                                <Form.Group controlId="exampleForm.ControlSelect1">
                                    <Form.Label>TITULO</Form.Label>
                                    <Form.Control type='text' className="mb-3" name="title" onChange={readFormData}>
                                    </Form.Control>
                                </Form.Group>
                                <Form.Group controlId="exampleForm.ControlSelect1">
                                    <Form.Label>SUBTITULO</Form.Label>
                                    <Form.Control type='text' className="mb-3" name="subtitle" onChange={readFormData}>
                                    </Form.Control>
                                </Form.Group>
                                <Form.Group controlId="exampleForm.ControlSelect1">
                                    <Form.Label>TEXTO</Form.Label>
                                    <Form.Control as="textarea" className="mb-3" name="content" onChange={readFormData}>
                                    </Form.Control>
                                </Form.Group>
                                <Form.Group controlId="exampleForm.ControlSelect1">
                                    <Form.Label>OPORTUNIDAD</Form.Label>
                                    <Form.Control as="select" className="mb-3" name="oportunityId" onChange={readFormData}>
                                        <option value="">Seleccione</option>
                                        {opportunities.map((opportunity, i) => (
                                            <option key={i} value={opportunity._id}>{opportunity.name}</option>
                                        ))}
                                    </Form.Control>
                                </Form.Group>
                                <Form.Group controlId="exampleForm.ControlSelect1">
                                    <Form.Label>IMAGEN DE FONDO</Form.Label>
                                    <Form.Control type='file' name="image" onChange={(e) => setFormData({ ...formdata, image: e.target.files[0] })} />
                                </Form.Group>
                            </div>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <PrimaryButton type='submit' variant="secondary" disabled={loading}>
                        {loading &&
                            <>
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    className='me-2'
                                />
                                <span className="visually-hidden">Loading...</span>
                            </>
                        }
                        crear
                    </PrimaryButton>
                    <PrimaryButton type='button' onClick={props.onHide}>Cerrar</PrimaryButton>
                </Modal.Footer>
            </Form>
        </Modal>
    );
}

export default ModalCreate