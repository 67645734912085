import React, { useState, useEffect } from 'react';
import { PrimaryButton } from '../../../general/Buttons';
// bootstrap
import { Container, Row, Col, Form, Button, Modal, ProgressBar } from 'react-bootstrap';
// style
import styled from '@emotion/styled'
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'

import clienteAxios from '../../../../config/Axios';
import { useSelector } from 'react-redux';
import axios from 'axios';


const Delete = styled.div`
    top:10px;
    right:10px;
`
const DeleteFile = styled(Button)`

`


export const Categories = ({ onChange, onRemove, title, url, description, _id, i, category, handleOnAddFile, handleOnRemoveFile }) => {

    let token = useSelector(state => state.auth.token)

    const [typeLink, setTypeLink] = useState("")

    useEffect(() => {
        setTypeLink(url ? url.includes('http') ? `Externo-${i}` : `Interno-${i}` : "")
    }, [url])


    return (
        <Row className="align-items-start border-bottom pb-4">
            <Form.Group as={Col} md="12" controlId="validationCustom03" className="mt-3">
                <Row>
                    <Form.Group as={Col} lg={12}>
                        <div className='d-flex justify-content-between align-items-center mb-2'>
                            <Form.Label className="mb-0">TITULO</Form.Label>
                            <DeleteFile variant="danger" onClick={onRemove}>
                                <svg width={"12"} fill="#fff" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                                    <path d="M376.6 427.5c11.31 13.58 9.484 33.75-4.094 45.06c-5.984 4.984-13.25 7.422-20.47 7.422c-9.172 0-18.27-3.922-24.59-11.52L192 305.1l-135.4 162.5c-6.328 7.594-15.42 11.52-24.59 11.52c-7.219 0-14.48-2.438-20.47-7.422c-13.58-11.31-15.41-31.48-4.094-45.06l142.9-171.5L7.422 84.5C-3.891 70.92-2.063 50.75 11.52 39.44c13.56-11.34 33.73-9.516 45.06 4.094L192 206l135.4-162.5c11.3-13.58 31.48-15.42 45.06-4.094c13.58 11.31 15.41 31.48 4.094 45.06l-142.9 171.5L376.6 427.5z" />
                                </svg>
                            </DeleteFile>
                        </div>
                        <Form.Control
                            type="text"
                            onChange={e => onChange(i, null, "title", e.target.value, "levelOne", _id === undefined ? "add" : _id)}
                            value={title}
                            className="mb-3"
                        />
                    </Form.Group>
                    <Col>
                        <Form.Label>DESCRIPCIÓN</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={4}
                            onChange={e => onChange(i, null, "description", e.target.value, "levelOne", _id === undefined ? "add" : _id)}
                            value={description}
                            className=""
                        />
                    </Col>
                    <Form.Group as={Col} lg={12} className="mt-4">
                        <Form.Label>ENLACE</Form.Label>
                        <div className="mb-3">
                            <Form.Check
                                inline
                                label="Externo"
                                name={`enlace-${i}`}
                                value="externo"
                                onChange={() => {
                                    setTypeLink(`Externo-${i}`)
                                    // onChange(i, null, "typeLink", "externo", "levelOne")
                                }}
                                checked={typeLink === `Externo-${i}`}
                                type={"radio"}
                                id={`inline-radio-1${i}`}
                            />
                            <Form.Check
                                inline
                                label="Interno"
                                name={`enlace-${i}`}
                                value="interno"
                                type={"radio"}
                                onChange={() => {
                                    setTypeLink(`Interno-${i}`)
                                    // onChange(i, null, "typeLink", "interno", "levelOne")
                                }}
                                checked={typeLink === `Interno-${i}`}
                                id={`inline-radio-2${i}`}
                            />
                        </div>
                    </Form.Group>
                    {typeLink === `Externo-${i}` ?
                        <Form.Group as={Col} lg={12}>
                            <Form.Label>ENLACE EXTERNO</Form.Label>
                            <Form.Control
                                type="text"
                                onChange={e => onChange(i, null, "link", e.target.value, "levelOne", _id === undefined ? "add" : _id)}
                                value={url}
                                placeholder="https://"
                                className="mb-3"
                            />
                        </Form.Group>
                        : typeLink === `Interno-${i}` ?
                            <>
                                <Form.Group as={Col} lg={12}>
                                    <Form.Label>ENLACE INTERNO</Form.Label>
                                    <Form.Control
                                        type="text"
                                        onChange={e => onChange(i, null, "link", e.target.value, "levelOne", _id === undefined ? "add" : _id)}
                                        value={url}
                                        placeholder="/categoria"
                                        className="mb-3"
                                    />
                                </Form.Group>
                                {category.files && category.files.map((file, i2) => {

                                    return (
                                        <div className='row' key={i2}>
                                            <Form.Group as={Col} lg={6}>
                                                <Form.Group controlId="formFile" className="mb-3">
                                                    <div className='d-flex justify-content-between align-items-center mb-2' css={css`height:38px;`}>
                                                        <Form.Label className="mb-0">ARCHIVO {i2 + 1}</Form.Label>
                                                    </div>
                                                    <div>
                                                        <Form.Control type="file" onChange={(e) => {
                                                            let file = e.target.files[0]
                                                            if (file) {
                                                                console.log("initLoading")
                                                                const config = { headers: { 'Authorization': "Bearer " + token, } };

                                                                let data = new FormData();
                                                                data.append('file', file);

                                                                clienteAxios.post('/api/categories/category-files-buffer', data, config)
                                                                    .then(res => {
                                                                        console.log("finishLoading")
                                                                        console.log(res)
                                                                        onChange(i, i2, "file", res.data.buffer.data, "levelTwo", _id === undefined ? "add" : _id)
                                                                        onChange(i, i2, "originalName", res.data.originalname, "levelTwo", _id === undefined ? "add" : _id)
                                                                    })
                                                                    .catch(err => {
                                                                        console.log("finishLoading")
                                                                        console.log(err.response)
                                                                    })
                                                            }
                                                        }} />
                                                    </div>
                                                    {/* <ProgressBar animated now={100} /> */}
                                                </Form.Group>
                                            </Form.Group>
                                            <Form.Group as={Col} lg={6}>
                                                <div className='d-flex justify-content-between align-items-center mb-2'>
                                                    <Form.Label className="mb-0">NOMBRE DEL ARCHIVO</Form.Label>
                                                    <DeleteFile type="button" variant="danger" onClick={() => handleOnRemoveFile(i, i2)}>
                                                        <svg width={"12"} fill="#fff" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                                            <path d="M135.2 17.69C140.6 6.848 151.7 0 163.8 0H284.2C296.3 0 307.4 6.848 312.8 17.69L320 32H416C433.7 32 448 46.33 448 64C448 81.67 433.7 96 416 96H32C14.33 96 0 81.67 0 64C0 46.33 14.33 32 32 32H128L135.2 17.69zM394.8 466.1C393.2 492.3 372.3 512 346.9 512H101.1C75.75 512 54.77 492.3 53.19 466.1L31.1 128H416L394.8 466.1z" />
                                                        </svg>
                                                    </DeleteFile>
                                                </div>
                                                <Form.Control
                                                    type="text"
                                                    className="mb-3"
                                                    onChange={(e) => {
                                                        onChange(i, i2, "name", e.target.value, "levelTwo", _id === undefined ? "add" : _id)
                                                    }}
                                                    value={file.name}
                                                />
                                            </Form.Group>
                                        </div>
                                    )
                                })}
                                {/* push file */}
                                <Form.Group as={Col} lg={12}>
                                    <PrimaryButton className='w-100' type='button' onClick={() => handleOnAddFile(i)}>Agregar archivo</PrimaryButton>
                                </Form.Group>
                            </>
                            :
                            <></>
                    }
                </Row>
            </Form.Group >
            {/* <Button className="btn-danger" onClick={onRemove}>X</Button> */}
        </Row >
    );
}
