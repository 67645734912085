import React, { useState, useContext, useEffect } from 'react';
import { PrimaryButton } from '../../general/Buttons';
// bootstrap
import { Container, Row, Col, Form, Button, Modal } from 'react-bootstrap';
// style
import styled from '@emotion/styled'
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { SocketContext } from '../../../context/SocketContext';
import Swal from 'sweetalert2';

const FormGeneral = styled(Form)`
    label{
        font-weight:500;
    }
`

const ModalMessages = (props) => {

    const [to, setTo] = useState();
    const [from, setFrom] = useState();

    useEffect(() => {
        setTo(props.to)
        setFrom(props.from)
    }, [props])


    const [message, setMessage] = useState('');
    const { socket } = useContext(SocketContext);


    const onChange = ({ target }) => {
        setMessage(target.value);
    }

    const onSubmit = (e) => {
        e.preventDefault();

        console.log(to, from)

        if (message.length === 0) { return; }

        setMessage('');

        socket.emit('newMessage', {
            from,
            to,
            content: message
            // files: null, // Carlos dice que no se envia si no hay archivo
        }, (response) => {
            console.log(response);
        });

        Swal.fire({
            title: 'Mensajes enviados',
            icon: 'success',
            confirmButtonText: 'Ok'
        })

        props.onHide();

    }

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <FormGeneral onSubmit={onSubmit}>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Mensajes Masivos
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className='my-5'>
                        <Col xs={12}>
                            <Row className='mt-4'>
                                <Form.Group as={Col} md={12} controlId="exampleForm.ControlSelect1">
                                    <Form.Label>MENSAJE</Form.Label>
                                    <Form.Control as={"textarea"} rows="6" className="mb-3" onChange={onChange} value={message}>
                                    </Form.Control>
                                </Form.Group>
                            </Row>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <PrimaryButton type="submit" variant="secondary">Enviar</PrimaryButton>
                    <PrimaryButton type='button' onClick={props.onHide}>Cerrar</PrimaryButton>
                </Modal.Footer>
            </FormGeneral>
        </Modal>
    );
}

export default ModalMessages