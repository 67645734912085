import React, { useState, useEffect } from 'react';
// bootstrap
import { Container, Row, Col, Form, Button, Spinner } from 'react-bootstrap';
// style
import styled from '@emotion/styled'
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
// dashboardstrcture
import DashboardStructure from '../../../general/DashboardStructure';
import { PrimaryButton } from '../../../general/Buttons';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import clienteAxios from '../../../../config/Axios';
import Swal from 'sweetalert2';

import { UpdateSliderAction } from '../../../../redux/actions/UpdateSlider';


const Delete = styled.div`
    top:10px;
    right:10px;
`

const Single = () => {

    let token = useSelector(state => state.auth.token);
    let loading = useSelector(state => state.updateSlider.loading);

    let { id } = useParams()
    let navigate = useNavigate()
    //DISPATCH
    const dispatch = useDispatch();
    //ACTIONS
    const UpdateSLiderFn = (body, navigate, token, id) => dispatch(UpdateSliderAction(body, navigate, token, id));

    const [opportunities, setOpportunities] = useState([])

    const [currentTitle, setcurrentTitle] = useState("")

    useEffect(() => {
        const config = { headers: { 'Authorization': "Bearer " + token } };//confi axios

        clienteAxios.get(`/api/sliders/obtain-slider/${id}`, config)
            .then((res) => {
                // console.log(res.data)
                setcurrentTitle(res.data.title ? res.data.title : "")
                setFormData({
                    title: res.data.title ? res.data.title : "",
                    subtitle: res.data.subtitle ? res.data.subtitle : "",
                    content: res.data.content ? res.data.content : "",
                    oportunityId: res.data.oportunityId ? res.data.oportunityId : "",
                })
                clienteAxios.get(`/api/oportunities/obtain-oportunities-by-admin`, config)
                    .then((res2) => {
                        // console.log(res2.data)

                        let equal = res2.data.filter(oportunity => {
                            return oportunity._id === res.data.oportunityId
                        })
                        let noEqual = res2.data.filter(oportunity => {
                            return oportunity._id !== res.data.oportunityId
                        })
                        // console.log(equal)
                        let oportunities = [...equal, ...noEqual]
                        setOpportunities(oportunities)
                    })
                    // error 2
                    .catch(error => { console.log(error.response) })
            })
            // error 1
            .catch(error => { console.log(error.response) })
    }, [])



    const [formdata, setFormData] = useState({
        title: "",
        subtitle: "",
        content: "",
        oportunityId: "",
        image: ""
    })

    const { title, subtitle, content, oportunityId, image } = formdata;

    const readFormData = (e) => {
        setFormData({
            ...formdata,
            [e.target.name]: e.target.value
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(formdata)

        if (title === "") {
            Swal.fire({ icon: 'error', title: 'Oops...', text: 'El campo titulo es requerido' })
        } else if (subtitle === "") {
            Swal.fire({ icon: 'error', title: 'Oops...', text: 'El campo subtitulo es requerido' })
        } else if (content === "") {
            Swal.fire({ icon: 'error', title: 'Oops...', text: 'El campo contenido es requerido' })
        } 
        // else if (oportunityId === "") {
        //     Swal.fire({ icon: 'error', title: 'Oops...', text: 'El campo oportunidad es requerido' })
        // } 
        else {
            let data = new FormData();
            data.append('subtitle', subtitle)
            data.append('content', content)

            if (oportunityId !== "") {
                data.append('oportunityId', oportunityId)
            }
            if (currentTitle !== title) {
                data.append('title', title)
            }
            if (image !== "") {
                data.append('image', image)
            }

            UpdateSLiderFn(data, navigate, token, id)
        }

    }


    const deleteSlider = () => {
        Swal.fire({
            title: '¿Estás seguro?',
            text: "¡No podrás revertir esto!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: '¡Sí, bórralo!'
        }).then((result) => {
            if (result.value) {
                clienteAxios.delete(`/api/sliders/delete-slider/${id}`, { headers: { 'Authorization': "Bearer " + token } })
                    .then((res) => {
                        Swal.fire(
                            '¡Eliminado!',
                            'El Slider ha sido eliminado.',
                            'success'
                        )
                        navigate("/sliders")
                    })
                    .catch(error => {
                        console.log(error.response)
                    })
            }
        })

    }

    const deleteOpportunity = (id) => {
        setFormData({
            ...formdata,
            oportunityId: ""
        })
    }


    return (
        <DashboardStructure asideRight titlePage="Slider | Salud">
            <Container fluid className="p-3">
                <Delete className='position-absolute' onClick={() => deleteSlider()}>
                    <Button variant="danger">ELIMINAR SLIDER</Button>
                </Delete>
                <Form onSubmit={handleSubmit}>
                    <Row className='my-5'>
                        <Col lg={7} md={12} >
                            <h4>DATOS</h4>
                            <div className='mt-4'>
                                <Form.Group controlId="exampleForm.ControlSelect1">
                                    <Form.Label>TITULO</Form.Label>
                                    <Form.Control type='text' className="mb-3" value={title} name="title" onChange={readFormData}>
                                    </Form.Control>
                                </Form.Group>
                                <Form.Group controlId="exampleForm.ControlSelect1">
                                    <Form.Label>SUBTITULO</Form.Label>
                                    <Form.Control type="text" rows={4} className="mb-3" value={subtitle} name="subtitle" onChange={readFormData}>
                                    </Form.Control>
                                </Form.Group>
                                <Form.Group controlId="exampleForm.ControlSelect1">
                                    <Form.Label>TEXTO</Form.Label>
                                    <Form.Control as='textarea' rows={4} className="mb-3" value={content} name="content" onChange={readFormData}>
                                    </Form.Control>
                                </Form.Group>
                                <Row className='d-flex align-items-center'>
                                    <Form.Label>OPORTUNIDAD</Form.Label>
                                    <Col xs={11}>
                                        <Form.Group controlId="exampleForm.ControlSelect1">
                                            <Form.Control as='select' className="mb-3" value={oportunityId} name="oportunityId" onChange={readFormData}>
                                                {oportunityId === "" ? <option value="">Seleccione una oportunidad</option> : null}
                                                {opportunities.map(oportunity => (
                                                    <option key={oportunity._id} value={oportunity._id}>{oportunity.name}</option>
                                                ))}
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={1}>
                                        <Button onClick={deleteOpportunity} variant='danger' className="mb-3">X</Button>
                                    </Col>
                                </Row>
                                <Form.Group controlId="exampleForm.ControlSelect1">
                                    <Form.Label>IMAGEN DE FONDO</Form.Label>
                                    <Form.Control type='file' name='image' onChange={(e) => setFormData({ ...formdata, image: e.target.files[0] })} />
                                </Form.Group>
                                <PrimaryButton type='submit' className="mt-5" variant="secondary" disabled={loading}>
                                    {loading &&
                                        <>
                                            <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                                className='me-2'
                                            />
                                            <span className="visually-hidden">Loading...</span>
                                        </>
                                    }
                                    Actualizar
                                </PrimaryButton>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </Container>
        </DashboardStructure>
    );
}

export default Single;